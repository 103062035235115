
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import UserTagsModal from '@/components/tags/UserTagsModal.vue'
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import PostCopyButton from './PostCopyButton.vue'
import PostDeleteButton from './PostDeleteButton.vue'
import PostDownloadImageButton from './PostDownloadImageButton.vue'
import PostRestoreDefaultImage from './PostRestoreDefaultImage.vue'

export default defineComponent({
  components: {
    BaseDropdown,
    UserTagsModal,
    PostRestoreDefaultImage,
    PostCopyButton,
    PostDownloadImageButton,
    PostDeleteButton
  },

  props: {
    blockUpdate: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    return {
      isDropdownOpen,
      closeDropdown
    }
  }
})
