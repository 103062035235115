
import { computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { isAfter } from 'date-fns'
import { DatePicker } from 'v-calendar';
import { useBreakpoints } from '@/composables/useBreakpoints'

export default defineComponent({
  components: {
    DatePicker
  },

  emits: [
    'setScheduleTo'
  ],

  setup (props, { emit }) {
    const scheduledTo = ref<Date | null>(null)

    const { breakpoints } = useBreakpoints()

    onMounted(() => {
      adjustScheduleDate()
    })

    const isMobile = computed(() => {
      return breakpoints.smaller('lg').value
    })

    const limitDateToSchedule = computed(() => {
      return new Date()
    })

    const getNextDate = (minutes: number) => {
      const date = new Date()
      const ms = 1000 * 60 * minutes
      return new Date(Math.ceil(date.getTime() / ms) * ms)
    }

    const adjustScheduleDate = () => {
      setInterval(() => {
        const dateToPublish = unref(scheduledTo)
        if (dateToPublish) {
          const limit = getNextDate(10)
          if (!isAfter(dateToPublish, limit)) {
            scheduledTo.value = limit
          }
        }
      }, 1000)
    }

    watch(scheduledTo, () => {
      emit('setScheduleTo', unref(scheduledTo))
    })
    
    return {
      scheduledTo,
      limitDateToSchedule,
      isMobile
    }
  }
})
