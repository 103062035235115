<template>
  <form @submit.prevent="handleRequestIdeas" class="mb-12 lg:w-5/6 mx-auto">
    <label for="keyword-select">
      {{ $t('page.content_bot.input_headline_label') }}
    </label>
    <div class="flex items-center justify-items-center">
      <base-input
        type="text"
        :placeholder="$t('page.content_bot.input_headline_placeholder')"
        v-model="headlineInput"
        class-name="rounded-r-none"
      />
      <button
        class="btn btn-secondary rounded-tl-none rounded-bl-none whitespace-nowrap"
        type="submit"
        :disabled="!isValidKeywords || !hasCredit"
      >
        <template v-if="isGenerating">
          <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
        </template>
        <template v-else>
          {{ $t('page.content_bot.resquest_contents_button_text') }}
        </template>
      </button>
    </div>
    <small v-html="$t('page.content_bot.input_headline_helper-html')" />
  </form>
  <recent-automated-contents />
  <template v-if="!hasCredit">
    <div
      class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-5/6"
    >
      <div
        class="text-lg text-center leading-relaxed"
        v-html="$t('sentence.no_credit-html')"
      ></div>
      <div class="text-sm text-center mt-12">
        <base-you-need-help-message />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import RecentAutomatedContents from '@/components/posts/content-generator/RecentAutomatedContents.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import { useProfile } from '@/composables/profile/useProfile'
import { usePost } from '@/api/publications/usePosts'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: { RecentAutomatedContents, BaseInput, BaseYouNeedHelpMessage },

  setup () {
    const headlineInput = ref('')

    const {
      isGenerating,
      requestContents
    } = useAutomatedContent()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const {
      profile
    } = useProfile()

    const {
      post,
      isPostLoaded
    } = usePost()

    watchEffect(() => {
      if (isPostLoaded.value && post.value.title) {
        headlineInput.value = post.value.title
      }
    })

    const category = computed(() => {
      const profileData = unref(profile)
      return profileData?.category ? profileData?.category.name : null
    })

    const headline = computed(() => {
      return unref(headlineInput).trim().replace('\n', '')
    })

    const isValidKeywords = computed(() => {
      return unref(headline).length >= 3
    })

    const handleRequestIdeas = async () => {
      if (unref(isValidKeywords)) {
        await requestContents(unref(headline))
      }
    }

    const hasCredit = computed(() => {
      return unref(currentHeadlineContentPack) && unref(currentHeadlineContentPack).used < unref(currentHeadlineContentPack).limit
    })

    return {
      hasCredit,
      headlineInput,
      isGenerating,
      isValidKeywords,
      category,
      handleRequestIdeas
    }
  }
})
</script>

<style scoped>

</style>