import { auth, firestore } from '@/plugins/firebase'
import { toRaw } from 'vue'
import { useStore } from 'vuex'

export const useProfileService = () => {
  const store = useStore()

  const updateProfile = async (form: any) => {
    const profileData = toRaw(form)

    const id = await store.dispatch('profiles/updateCurrent', profileData)

    return firestore.collection('profiles')
      .doc(id)
      .update(profileData)
  }

  const createProfileService = async (name: string) => {
    const currentUser = auth.currentUser

    if (currentUser) {
      const profileData = {
        step: 0,
        user: currentUser.uid,
        createdAt: new Date(),
        name
      }

      const { id } = await firestore.collection('profiles').add(profileData)

      store.commit('profiles/addProfile', {
        ...profileData,
        id
      })

      return id
    }
  }

  return {
    updateProfile,
    createProfileService
  }
}
