
import { defineComponent, computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'

export default defineComponent ({

  props:{
    hashtag:{
      type: Object, 
      default: () => {}
    }, 
    hashtagsProp:{
      type: Array, 
      default: () => []
    }, 
    titleProp:{
      type: String, 
      default: ''
    },
    defaultProp:{
      type: Boolean,
      required: true
    },
    canSelectGroup: {
      type: Boolean,
      required: false
    },
    id:{
      type: Number, 
      required: true
    },
  },

  emits: [
    'update-title',
    'update-default',
    'update-hashtags',
    'delete-group',
    'use-hashtags'
  ],

  setup(props, {emit}){
    const hashtagsToString = (hashtags: string[]) => {
      return hashtags
        .map((hashtag: string) => {
          return `#${hashtag.replaceAll('#', '')}`
        }) 
        .join(' ')
    }

    const deleteGroup = () => {
      emit('delete-group', props.hashtag)
    }

    const useHashtags = () => {
      emit('use-hashtags', hashtags.value.trim().split(' '))
    }

    const hashtags = computed({
      get: () => {
        return hashtagsToString(props.hashtagsProp as string[])
      },
      set: (value) => {
        hashtagsToArray(value as unknown as string)
      }
    })
    
    const defaults = computed({
      get: () => props.defaultProp,
      set: () => {
        emit('update-default', props.id)
       }
    })

    const title = computed({
      get: () => props.titleProp,
      set: (value) => {
        emit('update-title', {id: props.id, value})
      }
    })

    const hashtagsToArray = useDebounceFn((hashtags: string) => {
      const splitArray = hashtags
      .replace(/\s+/g, ' ')
      .split(' ')
      .map((hashtag: string) => {
        return `#${hashtag.replaceAll('#', '')}`
      })

      const newHashtags = Array.from([...new Set(splitArray)])
      emit('update-hashtags', {id: props.id, hashtags: newHashtags.slice(0,30)})
    }, 500)

    return {
      title,
      hashtags,
      defaults,
      deleteGroup,
      useHashtags
    }
  }
})
