<template>
  <div
    v-if="hasPosts"
    class="flex justify-between w-full mb-8 flex-wrap flex-col"
  >
    <div class="flex-1 flex w-full items-center relative">
      <i class="fas fa-search text-gray-500 absolute ml-3 z-10"></i>
      <multi-select
        class="bg-white w-full"
        v-model="contentFilter"
        :options="options"
        mode="tags"
        trackBy="name"
        label="name"
        createTag
        searchable
        :caret="false"
        :maxHeight="320"
        :placeholder="$t('page.my_content.form.search.placeholder')" 
        :noResultsText="$t('page.my_content.form.search.no_results')"
        :noOptionsText="$t('page.my_content.form.search.no_options')"
      >
        <template v-slot:option="{ option }">
          <div
            class="h-6 w-6 rounded mr-2"
            :class="`tag-${option.color}`" 
            v-if="option.type === 'tag'"
          >
          </div>
          {{ option.name }}
        </template>

        <template v-slot:tag="{ option, handleTagRemove }">
          <div class="multiselect-tag">
            <div
              class="h-6 w-6 rounded mr-2"
              :class="`tag-${option.color}`" 
              v-if="option.type === 'tag'"
            >
            </div>
            {{ option.name }}
            <button
              class=""
              @click.prevent
              @mousedown.prevent.stop="handleTagRemove(option, $event)"
            >
              <i class="fas fa-times fa-2x text-gray-800"></i>
            </button>
          </div>
        </template>
      </multi-select>
    </div>
    <div class="flex justify-end mx-2">
      <base-modal v-model:open="open" hide-footer>
        <template v-slot:button>
          <button
            class="text-sm text-right my-2 text-blue-600"
          >
            {{ $t('page.my_content.sentence.search_options') }}
          </button>
        </template>

        <div class="card-header">
          <div class="card-title">{{ $t('page.my_content.sentence.search_options') }}</div>
        </div>

        <div class="card-body text-left">
          <label for="orderBy">{{ $t('form.order.label') }}</label>
          <select
            class="w-full"
            id="orderBy"
            v-model="orderByFilterSelected"
          >
            <option value="asc">{{ $t('form.order.option.asc') }}</option>
            <option value="desc">{{ $t('form.order.option.desc') }}</option>
          </select>
        </div>

        <div class="card-footer flex justify-end">
          <button class="btn btn-primary" @click.prevent="close">
            {{ $t('action.finish') }}
          </button>
        </div>

      </base-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import MultiSelect from '@vueform/multiselect'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  components: {
    MultiSelect,
    BaseModal
  },
  
  props: {
    hasPosts: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      default: () => ([])
    },
    filters: {
      type: Array,
      default: () => ([])
    },
    order: {
      type: String,
      default: 'asc'
    }
  },

  emits : [
    'update:filters',
    'update:order'
  ],

  setup (props, { emit }) {
    const contentFilter = ref([])

    const orderByFilterSelected = ref(props.order)

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const orderByFilterOptions = computed(() => ([
      { code: 'asc', label: 'Mais recentes' },
      { code: 'desc', label: 'Mais antigos' }
    ]))

    watch(contentFilter, (filters) => {
      emit('update:filters', filters)
    })

    watch(orderByFilterSelected, (order) => {
      emit('update:order', order)
    })

    return {
      contentFilter,
      orderByFilterSelected,
      orderByFilterOptions,
      open,
      close
    }
  }
})
</script>

<style>
.multiselect-tag {
  margin-right: 0.25rem;
  padding-right: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  height: 2rem;
}
.multiselect-input {
  padding-left: 1.5rem;
}
.multiselect-placeholder {
  padding-left: 2.5rem;
  line-height: 1.25rem;
}
.multiselect-option {
  cursor: pointer;
}
.multiselect-tag i, .multiselect-tag i:before {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.multiselect-tag i:hover:before {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.multiselect-clear {
  display: none !important;
}
</style>
