<template>
  <base-modal
    v-model:open="open"
    v-if="hasThumbnail"
    hide-footer
  >
    <template v-slot:button>
      <button class="btn" v-bind="$attrs">
        <i class="fas fa-history"></i>
        {{ $t('page.reset_image.action.open_modal') }}
      </button>
    </template>

    <div class="card-header">
      <div class="card-title">{{ $t('page.reset_image.sentence.modal_title') }}</div>
    </div>
    <div class="card-body">
      <div v-if="!isPostLoaded || isFetchingImage">
        <i class="fas fa-spinner fa-spin"></i> {{ $t('page.reset_image.sentence.search_image') }}
      </div>
      <div v-else-if="isPostLoaded && !hasOriginalPublication">
        <p
          v-html="$t('page.reset_image.sentence.self_content_alert-html')"
        />
      </div>
      <div v-else>
        <div v-if="media">
          <img
            class="h-56 mx-auto mt-4 mb-8"
            :src="media"
          >
          <p
            v-html="$t('page.reset_image.sentence.restore_image_question-html')"
          />
        </div>
        <div v-else>
          <p>{{ $t('page.reset_image.sentence.restore_image_no_image') }}</p>
        </div>
      </div>
    </div>
    <div class="card-footer flex justify-end space-x-3">
      <button class="btn" @click.prevent="close">
        {{ $t('action.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click.prevent="restore"
        v-if="media"
      >
        <template v-if="isWorking">
          <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.wait') }}
        </template>
        <template v-else>
          {{ $t('page.reset_image.action.confirm') }}
        </template>
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, unref, watchEffect } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { usePost, useRestoreMedia } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  components: {
    BaseModal
  },

  setup () {
    const {
      isPostLoaded,
      post
    } = usePost()

    const {
      hasOriginalPublication,
      hasThumbnail,
      getMedia,
      restoreMedia,
      media,
      isWorking,
      isFetchingImage
    } = useRestoreMedia()

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    watchEffect(async () => {
      if (unref(hasOriginalPublication) && unref(open)) {
        await getMedia()
      }
    })

    const restore = async () => {
      const isRestored = await restoreMedia()
      if (isRestored) {
        close()
      }
    }

    return {
      isPostLoaded,
      hasOriginalPublication,
      hasThumbnail,
      media,
      restore,
      isWorking,
      isFetchingImage,
      open,
      close,
      post
    }
  }
})
</script>
