<template>
  <dashboard-content>
    <template v-if="!lockAccess" v-slot:actions>
      <div class="flex items-center space-x-2">
        <publish-button @publish="publish" @schedule="publish" />
        <post-save-button :disabled="lockAccess" />
      </div>
    </template>

    <div v-if="isPostLoaded">
      <base-navbar-tabs
        :items="tabOptions"
        v-model:active="activeTab"
      />

      <PostNavbar :selected-tab="activeTab" />

      <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
        <div class="w-full lg:w-2/3 h-full">
          <post-editor v-if="activeTab === 'content'" />
          <PostMediaView v-if="activeTab === 'media'" />
        </div>

        <div class="card w-full lg:w-1/3 h-full hidden lg:block">
          <post-sidebar-content />
        </div>
      </div>
    </div>

    <template
      v-slot:notification
      v-if="lockAccess"
    >
      <offer-alert-wrapper>
        {{ alertMessage }}
      </offer-alert-wrapper>
      <offer-modal offer="editPostOffer" hide-button/>
    </template>
  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, unref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { usePost } from '@/api/publications/usePosts'
import { useProfile } from '@/composables/profile/useProfile'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import OfferAlertWrapper from '@/components/freeTrial/OfferAlertWrapper.vue'
import OfferModal from '@/components/freeTrial/OfferModal.vue'
import PostSaveButton from '@/components/posts/post/PostSaveButton.vue'
import PublishButton from '@/components/publish/PublishButton.vue'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'
import BaseNavbarTabs from '@/components/base/BaseNavbarTabs.vue'
import PostEditor from '@/components/posts/post/PostEditor.vue'
import PostMediaView from '@/components/posts/post/PostMediaView.vue'
import PostNavbar from '@/components/posts/post/PostNavbar.vue'
import PostSidebarContent from '@/components/posts/post/PostSidebarContent.vue'
import { usePreventLeave } from '@/composables/usePreventLeave'
import { ContentPublisherGateway } from '@/services/ContentPublisherGateway'
// eslint-disable-next-line no-unused-vars
import { Source } from '@/services/ContentPublisherGatewayInterface'

export default defineComponent({
  components: {
    BaseNavbarTabs,
    PostEditor,
    DashboardContent,
    OfferAlertWrapper,
    OfferModal,
    PostSaveButton,
    PublishButton,
    PostMediaView,
    PostSidebarContent,
    PostNavbar
},
  setup () {
    const activeTab = ref('content')

    const tabOptions = computed(() => [
      { code: 'content', label: 'page.post.content_tab_title', icon: 'fas fa-font' },
      { code: 'media', label: 'page.post.media_tab_title', icon: 'fas fa-photo-video' }
    ])

    const router = useRouter()

    const { t } = useI18n()

    const {
      isPostLoaded,
      post,
      hasUnsavedData,
      fetchPost,
      createPost,
      clearStore,
      savePost
    } = usePost()

    const {
      profile,
      isFreeTrial,
      isFreeTrialActive,
      hasActiveSubscription,
      lockAccess
    } = useProfile()

    const alertMessage = computed(() => {
      if (isFreeTrial.value && !isFreeTrialActive.value) {
        return t('state.finish_free_trial')
      } else if (!hasActiveSubscription.value) {
        return t('state.subscription_expired')  
      }
      return ''
    })

    const postId = computed(() => {
      return router.currentRoute.value.params.id as string
    })

    const publish = async ({ socialNetworks, scheduledTo }: {socialNetworks: string[], scheduledTo?: Date}) => {
      const contentPublisherGateway = new ContentPublisherGateway()

      try {
        useToast.fire({
          icon: 'info',
          title: t('state.publishing.title'),
          text: t('state.publishing.message'),
        })

        await contentPublisherGateway.create(
          unref(profile).id,
          {
            contentId: unref(postId),
            sources: socialNetworks as Source[],
            ...(scheduledTo && { scheduledTo })
          }
        )
      } catch (error) {
        console.error(error)
      }
    }

    onMounted( async () => {
      if (unref(postId)) {
        await fetchPost(unref(postId))
      } else {
        await createPost()
      }
    })

    onBeforeUnmount(() => {
      clearStore()
    })

    const { isEditing } = usePreventLeave(savePost)

    watch(hasUnsavedData, () => {
      isEditing.value = unref(hasUnsavedData)
    })

    return {
      activeTab,
      tabOptions,
      isPostLoaded,
      post,
      lockAccess,
      publish,
      alertMessage,
    }
  }
})
</script>
