
import { defineComponent, unref, watchEffect } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { usePost, useRestoreMedia } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  components: {
    BaseModal
  },

  setup () {
    const {
      isPostLoaded,
      post
    } = usePost()

    const {
      hasOriginalPublication,
      hasThumbnail,
      getMedia,
      restoreMedia,
      media,
      isWorking,
      isFetchingImage
    } = useRestoreMedia()

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    watchEffect(async () => {
      if (unref(hasOriginalPublication) && unref(open)) {
        await getMedia()
      }
    })

    const restore = async () => {
      const isRestored = await restoreMedia()
      if (isRestored) {
        close()
      }
    }

    return {
      isPostLoaded,
      hasOriginalPublication,
      hasThumbnail,
      media,
      restore,
      isWorking,
      isFetchingImage,
      open,
      close,
      post
    }
  }
})
