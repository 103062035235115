
import { useToggle } from '@/composables/useToggle'
import { defineComponent, onMounted, readonly, ref, toRefs, unref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    tag: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:modelValue',
    'toggle-selection',
    'toggle-color',
    'toggle-text'
  ],

  setup (props, { emit }) {
    const cardRef = ref<HTMLDivElement>()

    const { tag } = toRefs(props)

    const text = ref('')

    const {
      isActive: isEditing,
      setInactive,
      setActive
    } = useToggle(false)

    const colors = readonly([
      'cyan',
      'blue',
      'green',
      'red',
      'pink',
      'purple',
      'amber',
      'deep-orange',
      'grey',
      'black'
    ])

    const changeTag = () => emit('toggle-selection', unref(tag).id)

    const changeColor = (color: string) => emit('toggle-color', {
      id: unref(tag).id,
      color
    })

    const changeText = () => {
      emit('toggle-text', {
        id: unref(tag).id,
        text: unref(text)
      })
    }

    onMounted(() => {
      text.value = unref(tag).text

      onClickOutside(cardRef, () => {
        setInactive()
      })
    })

    return {
      cardRef,
      text,
      colors,
      isEditing,
      setActive,
      setInactive,
      changeTag,
      changeColor,
      changeText
    }
  }
})
