
import { computed, defineAsyncComponent, defineComponent, ref, toRefs, unref } from 'vue'
import SocialAccountButtons from '@/components/social-media/SocialAccountButtons.vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'
import { usePostValidator } from '@/api/publications/usePosts'
import { noCache } from '@/composables/utils/noCache'

export default defineComponent({
  components: { SocialAccountButtons, BaseModal },
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const selected = ref('instagram')
    const { post } = toRefs(props)

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)
    
    const socialNetworks = computed(() => {
      return [
        {
          label: 'instagram',
          component: defineAsyncComponent(() => import('@/components/posts/preview/InstagramFeedPreview.vue')),
        },
        {
          label: 'facebook',
          component: defineAsyncComponent(() => import('@/components/posts/preview/FacebookFeedPreview.vue')),
        },
        {
          label: 'linkedin',
          component: defineAsyncComponent(() => import('@/components/posts/preview/LinkedinFeedPreview.vue')),
        },
        {
          label: 'twitter',
          component: defineAsyncComponent(() => import('@/components/posts/preview/TwitterFeedPreview.vue')),
        }
      ]
    })

    const thumbnail = computed(() => {
      const { thumbnail, creative } = unref(post)
      const output = creative?.thumbnail || thumbnail || null
      return output ? noCache(output) : ''
    })

    const { sources } = usePostValidator({
      content: unref(post).content,
      thumbnail: thumbnail.value,
      creative: unref(post).creative,
      hashtags: unref(post).hashtags,
    })

    const content = computed(() => {
      let hashtags = []
      if (unref(post).hashtags) {
        hashtags = unref(post).hashtags
          .map((h: any) => {
            return !h.startsWith('#') ? `#${h}` : h
          })
          .reduce((unique: any, item: any) => {
            return unique.includes(item) ? unique : [ ...unique, item ]
          }, [])
          .join(' ')
      }
      return unref(post).content + ' ' + hashtags
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    const preview = computed(() => {
      const result = unref(socialNetworks).find((sn: any) => sn.label === unref(selected))
      if (result && result.component) {
        return result.component
      }
      return defineAsyncComponent(() => import('@/components/posts/preview/DefaultPreview.vue'))
    })

    const isValid = computed(() => {
      const socialNetwork = unref(selected.value) as keyof typeof sources
      return sources[socialNetwork] || false
    })

    return {
      socialNetworks,
      selected,
      thumbnail,
      content,
      addDefaultSrc,
      preview,
      isValid,
      open,
      close
    }
  }
})
