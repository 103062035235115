
// eslint-disable-next-line no-unused-vars
import { defineComponent, PropType } from 'vue'
import UserTagsModal from '@/components/tags/UserTagsModal.vue'

interface Tag {
  id: string,
  color: string,
  text: string
}

type Tags = Tag[]

export default defineComponent({
  components: {
    UserTagsModal,
  },

  props: {
    tags: {
      type: Array as PropType<Tags>,
      default: () => ([])
    },
    showAddButton: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    return {}
  }
})
