<template>
  <div class="alert alert-info mb-4 flex flex-col lg:flex-row justify-between items-center space-x-2">
    <div class="flex space-x-2">
      <i class="fas fa-info-circle m-1"></i>
      <div>
        <slot />
      </div>
    </div>
    <router-link :to="{ name: 'plans' }" class="btn btn-primary mt-2 lg:mt-0">
      {{ $t('action.subscribe_now') }}
    </router-link>
  </div>
</template>
