<template>
  <div class="relative flex justify-center">
    <div
      v-if="isLoadingCreative"
      class="absolute rounded-lg flex h-full items-center justify-center w-full lg:w-4/6 bg-gray-500 opacity-50">
      <i class="fas fa-spin fa-spinner fa-2x text-white"></i>
    </div>
    <div
      v-if="!design?.length"
      class="relative flex flex-col rounded-lg h-full items-center justify-center w-full lg:w-4/6 p-16 bg-gradient-to-bl from-green-400 to-blue-500">
      <img
        :src="alternativeThumbnail"
        class="h-full w-full bg-transparent"
        alt=""
        @error="addDefaultSrc"
      >
      <span
        class="absolute bottom-4 flex justify-center items-center gap-2"
        v-if="mediaType === 'video'"
      >
        <i class="fas fa-spin fa-spinner fa-2x text-white"></i>
        <span class="whitespace-nowrap text-2xl font-semibold text-white">
          Gerando visualização
        </span>
      </span>
      <span
        class="absolute bottom-4 text-3xl font-semibold text-white whitespace-nowrap"
        v-else
      >
        Sem imagem
      </span>
    </div>
    <div
      v-else
      class="card h-full w-full lg:w-4/6"
    >
      <img
        v-if="mediaType === 'image'"
        :src="design[0]"
        class="rounded-lg"
        alt=""
        @error="addDefaultSrc"
      />
      <swiper
      v-if="mediaType === 'carrousel'"
        v-bind="swiperSettings"
        navigation
      >
        <swiper-slide
          v-for="item in design"
          :key="item"
        >
          <img
            :src="item"
            class="rounded-lg"
            alt=""
            @error="addDefaultSrc"
          />
        </swiper-slide>
      </swiper>
      <BaseVideoPlayer
        v-if="mediaType === 'video'"
        :src="design[0]"
        :thumbnail="thumbnail"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { usePost } from '@/api/publications/usePosts'
import BaseVideoPlayer from '@/components/base/BaseVideoPlayer.vue'
// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    BaseVideoPlayer,
    Swiper,
    SwiperSlide
  },

  setup() {
    const { isLoadingCreative, design, thumbnail, mediaType } = usePost()
    const alternativeThumbnail = computed(() => {
      return mediaType.value === "video" ? require("@/assets/img/no-image.png") : require("@/assets/img/no-image.png")
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require("@/assets/img/no-image.png")
      }
    }

    const swiperSettings = {
      loop: true,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
    }

    return {
      design,
      thumbnail,
      alternativeThumbnail,
      isLoadingCreative,
      mediaType,
      swiperSettings,
      addDefaultSrc
    };
  }
})
</script>

<style>
.swiper-button-next, .swiper-button-prev {
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 2rem;
  height: 2rem;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .swiper-button-next, .swiper-button-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}
</style>