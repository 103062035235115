<template>
  <form @submit.prevent="handleCreateContent" class="mb-12 lg:w-5/6 mx-auto">
    <label for="keyword-select">
      {{ $t('page.content_bot.input_headline_label') }}
    </label>
    <div class="flex items-center justify-items-center">
      <base-input
        type="text"
        :placeholder="$t('page.content_bot.input_headline_placeholder')"
        v-model="headlineInput"
        class-name="rounded-r-none"
      />
      <button
        class="btn btn-secondary rounded-tl-none rounded-bl-none whitespace-nowrap"
        type="submit"
        :disabled="!isValidHeadline || !hasCredit"
      >
        <template v-if="_isSendingMessage">
          <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
        </template>
        <template v-else>
          {{ $t('page.content_bot.resquest_contents_button_text') }}
        </template>
      </button>
    </div>
    <small v-html="$t('page.content_bot.input_headline_helper-html')" />
  </form>

  <div class="mt-4 lg:w-5/6 mx-auto whitespace-pre-wrap" v-html="createdContent" />

  <div
    v-if="hasContent"
    class="controls mt-4 lg:w-5/6 mx-auto flex justify-end items-center gap-2"
  >
    <button
      class="btn"
      @click="handleCopy()"
      :title="$t('action.copy')"
      :disabled="_isSendingMessage"
      v-if="isCopySupported"
    >
      <i class="fas fa-copy"></i>
      {{ $t('action.copy') }}
    </button>
    
    <button
      class="btn btn-primary"
      @click="handleUsePost()"
      :title="$t('action.use_content')"
      :disabled="_isSendingMessage"
    >
      <i class="fas fa-file-import"></i>
      {{ $t('action.use_content') }}
    </button>
  </div>

  <recent-automated-contents />
  <template v-if="!hasCredit">
    <div
      class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-5/6"
    >
      <div
        class="text-lg text-center leading-relaxed"
        v-html="$t('sentence.no_credit-html')"
      ></div>
      <div class="text-sm text-center mt-12">
        <base-you-need-help-message />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import RecentAutomatedContents from '@/components/posts/content-generator/RecentAutomatedContents.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import { useProfile } from '@/composables/profile/useProfile'
import { usePost } from '@/api/publications/usePosts'
import { ManybotChatGateway } from '@/services/ManybotChatGateway'
import EventBus from '@/plugins/EventBus'
import { useClipboard } from '@vueuse/core'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'
import { useScrollTop } from '@/composables/useScrollTop'
import BaseYouNeedHelpMessage from '../base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: { RecentAutomatedContents, BaseInput, BaseYouNeedHelpMessage },

  setup () {
    const headlineInput = ref('')
    const createdContent = ref('')
    const _isSendingMessage = ref(false)

    const { t } = useI18n()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const {
      profile
    } = useProfile()

    const {
      post,
      isPostLoaded,
      updatePost,
    } = usePost()

    const manybotChatGateway = new ManybotChatGateway()

    watchEffect(() => {
      if (isPostLoaded.value && post.value.title) {
        headlineInput.value = post.value.title
      }
    })

    const headline = computed(() => {
      return unref(headlineInput).trim().replace('\n', '')
    })

    const isValidHeadline = computed(() => {
      return unref(headline).trim().length >= 3
    })

    const hasCredit = computed(() => {
      return unref(currentHeadlineContentPack) && unref(currentHeadlineContentPack).used < unref(currentHeadlineContentPack).limit
    })

    const hasContent = computed(() => {
      return !!createdContent.value.trim().length
    })

    const handleCreateContent = async () => {
      if (!unref(isValidHeadline)) {
        return
      }

      _isSendingMessage.value = true

      const assistantMessage = await manybotChatGateway.createContent({
        profileId: unref(profile).id,
        topic: unref(headline).trim(),
        // target: "rede social",
        // specialization: unref(profile).specialization,
        // audience: unref(profile).audience,
        // text_size: "180 palavras",
        // voice_tone: "simples e descolado",
        // focus: "engajamento",
        // goal: unref(profile).contentPurpose,
        // language: "português"
      })

      createdContent.value = assistantMessage.message

      _isSendingMessage.value = false

      setTimeout(() => {
        useScrollTop('.controls')
      }, 200);
    }

    const handleUsePost = async () => {
      updatePost({content: createdContent.value})
      EventBus.emit('content.used')
    }

    const handleCopy = () => {
      const sentence = createdContent.value.replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    return {
      hasCredit,
      headlineInput,
      _isSendingMessage,
      hasContent,
      isValidHeadline,
      createdContent,
      isCopySupported,
      handleCreateContent,
      handleUsePost,
      handleCopy,
    }
  }
})
</script>

<style scoped>

</style>