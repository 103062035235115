import { auth, firestore } from '@/plugins/firebase'
import { useI18n } from 'vue-i18n'

interface ILabel {
  id: string;
  text: string;
  color: string;
  isUsed?: boolean;
}

export const useLabels = () => {
  const { t } = useI18n()

  const tags = [
    {
      color: 'blue',
      text: t('constant.demo_tag_1')
    },
    {
      color: 'green',
      text: t('constant.demo_tag_2')
    },
    {
      color: 'amber',
      text: t('constant.demo_tag_3')
    },
    {
      color: 'red',
      text: null
    },
    {
      color: 'deep-orange',
      text: null
    },
    {
      color: 'purple',
      text: null
    }
  ]

  const getTags = async () => {
    const currentUser = auth.currentUser
  
    if (currentUser) {
      const tagsRef = firestore
        .collection('users')
        .doc(currentUser.uid)
        .collection('tags')
  
      let querySnapshot = await tagsRef.get()
  
      if (querySnapshot.empty) {
        const batch = firestore.batch()
  
        tags.forEach((tag) => {
          batch.set(tagsRef.doc(), tag)
        })
  
        await batch.commit()
  
        querySnapshot = await tagsRef.get()
      }
  
      return querySnapshot.docs.map((tagDoc) => ({
        id: tagDoc.id,
        ...tagDoc.data() as Omit<ILabel, 'id'>
      }))
    }
  
    return []
  }

  const saveTag = async (tag: ILabel) => {
    const currentUser = auth.currentUser
  
    if (currentUser) {
      await  firestore
        .collection('users')
        .doc(currentUser.uid)
        .collection('tags')
        .doc(tag.id)
        .set({
            color: tag.color,
            text: tag.text
          },
          { merge: true }
        )
    }
  }

  return {
    getTags,
    saveTag
  }
}
