import { computed, onBeforeMount, toRaw, unref } from "vue"
import { useStore } from "vuex"
import { useProfile } from "../profile/useProfile"

export const useContentPack = () => {
  const store = useStore()

  const {
    profile
  } = useProfile()

  onBeforeMount(() => {
    if (!store.state.contentPack.isLoaded) {
      fetchContentPacks()
    }
  })

  const fetchContentPacks = () => {
    if (unref(profile) && unref(profile).id) {
      store.dispatch('contentPack/fetch', unref(profile).id)
    }
  }

  const currentHeadlineContentPack = computed(() => {
    return store.getters['contentPack/currentHeadlinePack']
  })

  const contentPacks = computed(() => {
    return toRaw(store.state.contentPack.ideas)
  })

  return {
    contentPacks,
    currentHeadlineContentPack
  }
}