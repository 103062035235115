
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, unref, watch } from 'vue'

export default defineComponent({
  props: { 
    modelValue: String, 
  },

  events: [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const {
      socialIcons
    } = useSocialAccounts()

    const buttons = computed(() => {
      return Object.entries(unref(socialIcons)).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    watch(buttons, () => {
      const selected = Object.values(unref(buttons)).find(button => button.available)
      if (selected) {
        emit('update:modelValue', selected.source)
      }
    })

    return {
      buttons
    }
  }
})
