
import { computed, defineComponent } from 'vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import { useToggle } from '@/composables/useToggle'
import IdeaGeneratorButton from '../idea-generator/IdeaGeneratorButton.vue'
import ContentGeneratorButton from '../content-generator/ContentGeneratorButton.vue'
import CreateContentButton from '@/components/manyBot/CreateContentButton.vue'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: {
    BaseDropdown,
    IdeaGeneratorButton,
    ContentGeneratorButton,
    CreateContentButton
  },

  props: {
    blockUpdate: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    const { profile } = useProfile()

    const useManybot = computed(() => {
      return !!profile.value.specialization?.length
    })

    return {
      isDropdownOpen,
      closeDropdown,
      useManybot
    }
  }
})
