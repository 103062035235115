
import { defineComponent } from 'vue'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import Idea from './Idea.vue'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  components: { Idea },
  setup () {
    const {
      newIdeas,
      removeIdea,
    } = useIdeas()

    const {
      isPostLoaded,
    } = usePost()

    return {
      isPostLoaded,
      newIdeas,
      removeIdea
    }
  }
})
