<template>
  <span class="font-semibold text-right ml-2">
    <i class="far fa-clock text-lg" v-if="status === 'waiting'"></i>
    <i class="far fa-check-circle text-lg text-green-600" v-if="status === 'published'"></i>
    <i class="fas fa-exclamation-circle text-lg text-yellow-500" v-if="status === 'error'"></i>
    <span
      class="ml-2"
      v-if="!iconOnly"
    >
      {{ statusDescription }}
    </span>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSchedules } from '@/composables/schedule/useSchedules'

export default defineComponent({
  props: {
    isPublished: {
      type: Boolean,
      default: false
    },
    scheduledTo: {
      type: Date,
      required: true
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()

    const { getStatus } = useSchedules()

    const status = getStatus(props.isPublished, props.scheduledTo)

    const statusDescription = computed(() => {
      const descriptions = {
        published: t('sentence.schedule_status_published'),
        error: t('sentence.schedule_status_error'),
        waiting: t('sentence.schedule_status_waiting')
      }

      return descriptions[unref(status)]
    })

    return {
      status,
      statusDescription
    }
  }
})
</script>
