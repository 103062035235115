<template>
  <base-modal v-model:open="isActive" hide-footer>
    <template v-slot:button>
      <button
        @click.prevent="toggle"
        class="btn"
      >
        <i class="fas fa-times"></i>
        {{ $t('action.reject') }}
      </button>
    </template>

    <div class="card-header">
      <div class="card-title">
        {{ $t('page.review.sentence.why_are_rejecting') }}
      </div>
    </div>
    <div class="card-body text-left">
      <p class="font-semibold">{{ $t('page.review.sentence.tell_about_rejection') }}</p>

      <ul class="ml-4 my-4">
        <li
          class="flex items-center space-x-2"
          v-for="(reason, index) in reasons"
          :key="index"
        >
          <input class="cursor-pointer" type="radio" :id="`reject-reason-${index}`" :value="reason.value" v-model="selectedreason" />
          <label class="cursor-pointer" :for="`reject-reason-${index}`">{{ reason.label }}</label>
        </li>
      </ul>

      <p class="my-4">{{ $t('page.review.sentence.describe_about_rejection') }}</p>

      <textarea
        class="w-full border border-gray-400 rounded p-2"
        v-model="feedback"
        rows="3"
      >
      </textarea>

    </div>
    <div class="card-footer flex justify-end space-x-4">
      <button
        class="btn"
        @click.prevent="toggle"
      >
        {{ $t('action.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="!valid"
        @click.prevent="reject"
      >
        {{ $t('action.reject') }}
      </button>
    </div>

  </base-modal>
</template>

<script>
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import { computed, ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'
export default {
  components: { BaseModal },

  emits: [
    'reject'
  ],

  setup (props, { emit }) {
    const { t } = useI18n()
    const selectedreason = ref(null)
    const feedback = ref('')

    const reasons = computed(() => ([
      {
        label: t('page.review.sentence.just_bounce'),
        value: 'Rejeição'
      },
      {
        label: t('page.review.sentence.inadequate'),
        value: 'Inadequada'
      },
      {
        label: t('page.review.sentence.with_error'),
        value: 'Erros'
      }
    ]))

    const {
      isActive,
      toggle
    } = useToggle(false)

    const valid = computed(() => {
      return !!unref(selectedreason)
    })

    const reject = () => {
      emit('reject', {
        reason: unref(selectedreason),
        feedback: unref(feedback),
      })
    }
    

    return {
      isActive,
      toggle,
      reasons,
      selectedreason,
      feedback,
      valid,
      reject
    }
  }
}
</script>