import { useStore } from 'vuex'
import { useAxios } from '@/composables/useAxios'
import { computed, unref } from 'vue'

export const useSocialAccounts = () => {
  const store = useStore()
  const profileId = store.getters['profiles/current'].id

  const isLoaded = computed(() => {
    return store.state.socialAccounts.isLoaded
  })

  const facebookPermissions = [
    'pages_show_list',
    'read_insights',
    'pages_read_engagement',
    'pages_read_user_content',
    'pages_manage_posts',
    'instagram_basic',
    'instagram_manage_comments',
    'instagram_manage_insights',
    'instagram_content_publish'
  ]

  const fetchFacebookPermissions = async () => {
    const { httpFunctions } = await useAxios()

    const { data: permissions } = await httpFunctions
      .get<string[]>(`/getFacebookPermissions/${profileId}`)

    return permissions
  }

  const isInstagramBusiness = async () => {
    const { httpFunctions } = await useAxios()

    const { data: permissions } = await httpFunctions
      .get<boolean>(`/isInstagramBusiness/${profileId}`)

    return permissions
  }

  const getAvailables = async () => {
    try {
      // const profileId = store.getters['profiles/current'].id
      const { httpFunctions } = await useAxios()
      const { data: availables } = await httpFunctions.get<string[]>(`/socialAccounts/enabled/${profileId}`)
      return availables
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const socialIcons = computed(() => {
    const availableSources = Object.keys(unref(availables))

    return {
      instagram: {
        class: "fa-instagram",
        style: {
          "background-color": "#e4405f"
        },
        available: availableSources.includes('instagram')
      },
      instagramStories: {
        class: "fa-instagram",
        style: {
          "color": "#e4405f",
          "border": "1px solid #e4405f"
        },
        available: availableSources.includes('instagram')
      },
      'instagram-story': {
        class: "fa-instagram",
        style: {
          "color": "#e4405f",
          "border": "1px solid #e4405f"
        },
        available: availableSources.includes('instagram')
      },
      facebook: {
        class: "fa-facebook-f",
        style: {
          "background-color": "#3b5999"
        },
        available: availableSources.includes('facebook')
      },
      linkedin: {
        class: "fa-linkedin",
        style: {
          "background-color": "#0077b5"
        },
        available: availableSources.includes('linkedin')
      },
      twitter: {
        class: "fa-twitter",
        style: {
          "background-color": "#55acee"
        },
        available: availableSources.includes('twitter')
      },
      tiktok: {
        class: "fa-tiktok",
        style: {
          "background-color": "#040404"
        },
        available: availableSources.includes('twitter')
      },
      youtube: {
        class: "fa-youtube",
        style: {
          "background-color": "#ff0000"
        },
        available: availableSources.includes('youtube')
      }
    }
  })

  const availables = computed(() => store.getters['socialAccounts/availables'])

  const connectedInstagram = computed(() => {
    return store.getters['socialAccounts/connectedInstagram']
  })

  return {
    getAvailables,
    facebookPermissions,
    fetchFacebookPermissions,
    isInstagramBusiness,
    isLoaded,
    socialIcons,
    connectedInstagram,
    availables
  }
}