
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

interface IBreadcrumb {
  name: string;
  link?: string;
}

export default defineComponent({
  setup () {
    const router = useRouter()
    const breadcrumbList = ref<IBreadcrumb[]|null>(null)
    
    watchEffect(() => {
      const routerMeta = router.currentRoute.value.meta
      if ('breadcrumb' in routerMeta) {
        const breadcrumb = routerMeta.breadcrumb as IBreadcrumb[]
        breadcrumbList.value = breadcrumb
      }
    })

    return {
      breadcrumbList
    }
  }
})
