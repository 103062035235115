<template>
  <div class="card" >
    <div class="card-body p-4">
      <div class="border-gray-300 border-l-4 pl-3">
        <p v-html="automatedContent.content" ></p>
      </div>
      <div class="mt-2 grid grid-cols-2">
        <div v-if="showContentProps">
          <small><span class="font-semibold capitalize-first">{{$t('sentence.title')}}:</span> {{automatedContent.headline}}</small>
        </div>
        <div v-if="showContentProps">
          <small><span class="font-semibold capitalize-first">{{$t('page.settings.category.title')}}:</span> {{automatedContent.category.name}}</small>
        </div>
        <div v-if="showContentProps">
          <small><span class="font-semibold capitalize-first">{{$t('sentence.created_at')}}:</span> {{format(automatedContent.createdAt)}}</small>
        </div>
        <div>
          <small><span class="font-semibold capitalize-first">{{$t('sentence.usage_tokens')}}:</span> {{automatedContent.usage.totalTokens}}</small>
        </div>
        <div v-if="showContentProps && automatedContent.approved">
          <small><span class="font-semibold capitalize-first">{{$t('sentence.used_at')}}:</span> {{format(automatedContent.updatedAt)}}</small>
        </div>
      </div>
      <div class="flex justify-between items-center space-x-4 mt-2">
        <div>
          <template v-if="canDelete">
            <button
              class="btn btn-danger ghost"
              @click="remove(automatedContent.id)"
              :title="$t('action.delete')"
            >
              <i class="fa fa-trash"></i>
              {{ $t('action.delete') }}
            </button>
          </template>
        </div>
        <div class="flex gap-4">
          <template v-if="canCopy">
            <button
              class="btn"
              @click="handleCopy()"
              :title="$t('action.copy')"
              v-if="isCopySupported"
            >
              <i class="fas fa-copy"></i>
              {{ $t('action.copy') }}
            </button>
          </template>
          <template v-if="canCreatePost && !automatedContent.approved">
            <button
              class="btn btn-primary"
              @click="handleCreatePost()"
              :title="$t('action.save')"
              :disabled="isCreatingPost"
            >
              <i class="far fa-file"></i>
              {{ $t('action.save') }}
            </button>
          </template>
          <template v-if="canUse && isPostLoaded">
            <button
              class="btn btn-primary"
              @click="handleUsePost()"
              :title="$t('action.use_content')"
              :disabled="isCreatingPost"
            >
              <i class="fas fa-file-import"></i>
              {{ $t('action.use_content') }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { usePost } from '@/api/publications/usePosts'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { defineComponent, ref, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n'
// import { useRouter } from 'vue-router'
import { useDateFns } from '@/composables/locale/useDateFns'
import EventBus from '@/plugins/EventBus'

export default defineComponent({
  props: {
    automatedContent: {
      type: Object,
      default: () => ({})
    },
    canCopy: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canCreatePost: {
      type: Boolean,
      default: false
    },
    canUse: {
      type: Boolean,
      default: false
    },
    showContentProps: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { automatedContent } = toRefs(props)
    const isCreatingPost = ref(false)
    
    const { t } = useI18n()

    // const router = useRouter()

    const {
      isPostLoaded,
      updatePost,
      createPost,
      savePost
    } = usePost()

    const {
      remove,
      makeUsed,
    } = useAutomatedContent()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const { format } = useDateFns()

    const handleCopy = () => {
      const sentence = unref(automatedContent).content.replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const handleCreatePost = async () => {
      isCreatingPost.value = true
      createPost({
        title: automatedContent.value.headline,
        content: automatedContent.value.content
      })
      await savePost()
      await makeUsed(automatedContent.value.id)
      isCreatingPost.value = false
      // router.push({ name: 'edit-post', params: { id: post.value.uid }})
    }

    const handleUsePost = async () => {
      await makeUsed(automatedContent.value.id)
      updatePost({content: automatedContent.value.content})
      EventBus.emit('content.used')
    }

    return {
      isCreatingPost,
      isCopySupported,
      isPostLoaded,
      handleCopy,
      remove,
      handleCreatePost,
      handleUsePost,
      format
    }
  }
})
</script>