<template>
  <div class="flex flex-col w-full">
    <label
      :for="name"
      :class="{'text-red-700': hasError}"
      v-if="label"
    >
      {{ label }}
    </label>
    <div class="flex items-center relative">
      <i
        class="absolute text-gray-700 px-2 "
        :class="leftIcon"
        v-if="hasLeftIcon"
      ></i>
      <textarea
        class="block w-full"
        :class="{ 'pl-8': hasLeftIcon, 'input-error': hasError }"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :required="required"
        :rows="rows"
        :ref="ref"
        v-model="value"
      >
      </textarea>
    </div>
    <div class="flex flex-row-reverse justify-between">
      <div v-if="showCounter" class="text-sm text-gray-500 text-right">
        {{ countWords }}
      </div>
      <div v-if="help || errorMessage">
        <span
          class="text-sm text-gray-500 block"
          v-if="help"
          v-html="help">
        </span>
        <span
          class="text-sm text-red-500 block"
          v-if="errorMessage"
          v-html="errorMessage">
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, unref, toRefs, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  
  props: {
    name: String,
    label: String,
    help: String,
    maxWords:{
      type: Number, 
      default: 0
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    modelValue: {
      type: String,
      default: '',
    },
    rows: {
      type: String,
      default: '5',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    autocomplete: {
      required: false,
      type: String,
      default: '',
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: false,
    },
    ref: {
      required: false,
      type: String,
      default: '',
    },
    leftIcon: {
      required: false,
      type: String,
      default: '',
    },
    showCounter: {
      required: false,
      type: Boolean,
      default: false,
    },
    counterType: {
      required: false,
      type: String,
      default: 'characters',
    },
    counterMin: {
      required: false,
      type: Number,
      default: 50,
    },
  },

  emits : [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const { placeholder, leftIcon } = toRefs(props)

    const { t } = useI18n()

    const value = computed({ 
      get: () => props.modelValue, 
      set: (value) => emit('update:modelValue', value) 
    }) 

    const hasPlaceholder = computed(() => {
      return !!unref(placeholder).trim().length
    })

    const hasLeftIcon = computed(() => {
      return !!unref(leftIcon).trim().length
    })

    const counter = computed(() => {
      let result = 0
      if (value.value.trim().length) {
        if (props.counterType === 'characters') {
          result = value.value.trim().length
        } else {
          result = value.value.trim().split(' ').length
        }
      }
      return result
    })

    const countWords = computed(() => {
      if (props.counterType === 'characters') {
        return t('sentence.characters_counter-dynamic', { current: counter.value, min: props.counterMin })
      }
      return t('sentence.word_counter-dynamic', { current: counter.value, min: props.counterMin })
    })

    return {
      value,
      hasPlaceholder,
      hasLeftIcon,
      countWords
    }
  }
})
</script>

<style>
  @layer components {
    .input-error {
      @apply border-red-700 !important;
    }

  }
</style>
