<template>
  <div
    class="flex flex-row justify-start items-center space-x-4"
  >
    <button
      class="select-button"
      v-for="button in buttons"
      :key="button.source"
      :style="button.style"
      :class="{ active: button.source === modelValue }"
      @click="(event) => $emit('update:modelValue', button.source)"
    >
      <i
        class="fab text-white"
        :class="button.class"
      >
      </i>
    </button>
  </div>
</template>

<script lang="ts">
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, unref, watch } from 'vue'

export default defineComponent({
  props: { 
    modelValue: String, 
  },

  events: [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const {
      socialIcons
    } = useSocialAccounts()

    const buttons = computed(() => {
      return Object.entries(unref(socialIcons)).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    watch(buttons, () => {
      const selected = Object.values(unref(buttons)).find(button => button.available)
      if (selected) {
        emit('update:modelValue', selected.source)
      }
    })

    return {
      buttons
    }
  }
})
</script>

<style scoped>
button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
button:not(.active) {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity)) !important;
}
button.active {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
button:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
}
</style>