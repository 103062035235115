<template>
  <div class="card p-3 px-4">
    <div v-if="packagesInfo.hasPackages" class="flex justify-between items-center mb-3">
      <div class="mr-4">
        <div
          class="quote-title"
          v-html="$t('page.review.quote.sentence.current_package_count-dynamic-html', { currentPackage: packagesInfo.currentPackage, totalPackages: packagesInfo.totalPackages })"
        />
      </div>
      <div>
        <base-modal v-model:open="showPackagesInfoModal" hide-footer>
          <template v-slot:button>
            <button @click.prevent="togglePackagesInfoModal">
              <i class="far fa-question-circle text-blue-600"></i>
            </button>
          </template>

          <div class="card-header">
            <div class="card-title">
              {{ $t('page.review.quote.sentence.modal_title') }}
            </div>
          </div>
          <div class="card-body h-72 overflow-y-auto">
            <p
              class="mb-4 text-left"
              v-html="$t('page.review.quote.sentence.modal_description-html')"
            />
            <table class="text-sm">
              <thead>
                <tr>
                  <th class="text-right">{{ $t('page.review.quote.table_head.package') }}</th>
                  <th class="text-center">{{ $t('page.review.quote.table_head.contents') }}</th>
                  <th class="text-center">{{ $t('page.review.quote.table_head.approved') }}</th>
                  <th class="text-center">{{ $t('page.review.quote.table_head.rejecteds') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pack, index) in packagesInfo.packages" :key="index">
                  <td
                    class="text-right"
                    :class="{ 'text-gray-400': pack.completed, 'font-semibold, bg-green-50': pack.current }"
                  >
                    <div
                      class="flex flex-row-reverse items-center justify-between w-full"
                      :class="{ 'text-gray-400': pack.completed }"
                    >
                      {{ index + 1 }}
                      <i
                        class="fas fa-arrow-right text-green-400"
                        v-if="pack.current"
                      ></i>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    :class="{ 'text-gray-400': pack.completed, 'font-semibold, bg-green-50': pack.current }"
                  >
                    {{ pack.contents }}
                  </td>
                  <td
                    class="text-center"
                    :class="{ 'text-gray-400': pack.completed, 'font-semibold, bg-green-50': pack.current }"
                  >
                    {{ pack.used }}
                  </td>
                  <td
                    class="text-center"
                    :class="{ 'text-gray-400': pack.completed, 'font-semibold, bg-green-50': pack.current }"
                  >
                    {{ pack.rejected }}
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="my-4 text-left text-sm">
              * {{ $t('page.review.quote.sentence.see_support_center')}} <a href="https://suporte.manycontent.com" target="_blank" class="text-blue-600">{{ $t('action.support_center') }}</a>
            </p>
          </div>
          <div class="card-footer flex justify-end">
            <button class="btn btn-primary" @click.prevent="togglePackagesInfoModal">
              {{ $t('action.finish') }}
            </button>
          </div>

        </base-modal>
      </div>
    </div>
    <div class="flex items-center">
      <div class="relative progress-box">
        <svg
          :height="radius * 2"
          :width="radius * 2"
          class="absolute"
        >
          <circle
            stroke="#BDBDBD"
            fill="transparent"
            :stroke-dasharray="`${circumference} ${circumference}`"
            :style="{ bgStrokeDashoffset }"
            :stroke-width="stroke"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
          />
          <circle
            stroke="#068CC4"
            fill="transparent"
            :stroke-dasharray="`${circumference} ${circumference}`"
            :style="{ strokeDashoffset }"
            :stroke-width="stroke"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
          />
        </svg>
        <div class="progress-icon">
          <i class="far fa-thumbs-up text-blue-500"></i>
        </div>
      </div>
      <div class="pl-4">
        <div>
          <span class="font-sora font-bold text-2xl">{{ usageText }}</span>
        </div>
        <div>
          <span class="text-gray-600 text-sm">{{ cardTitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { BaseModal },

  props: {
    limit: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 0
    },
    packages: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const {
      isActive: showPackagesInfoModal,
      toggle: togglePackagesInfoModal
    } = useToggle(false)

    const { t } = useI18n()

    const cardTitle = computed(() => {
      return props.title || t('page.review.quote.sentence.approved_content')
    })
    
    const usageText = computed(() => t('page.review.sentence.quote_current_usage_values-dynamic', { current: props.current, limit: props.limit }))

    const progress = computed(() => Math.round(props.current * 100 / props.limit))

    const packagesInfo = computed(() => {
      const currentPackIndex = props.packages.findIndex((pack: any) => pack.isCurrent)
      return {
        hasPackages: currentPackIndex >= 0,
        currentPackage: currentPackIndex + 1,
        totalPackages: props.packages.length,
        packages: props.packages.map((pack: any, index) => ({ ...pack, current: index === currentPackIndex }))
      }
    })

    const radius = 30
    const stroke = 2
    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = computed(() => unref(circumference) - unref(progress) / 100 * unref(circumference))
    const bgStrokeDashoffset = computed(() => unref(circumference) - 100 / 100 * unref(circumference))

    return {
      usageText,
      radius,
      stroke,
      circumference,
      normalizedRadius,
      strokeDashoffset,
      bgStrokeDashoffset,
      packagesInfo,
      showPackagesInfoModal,
      cardTitle,
      togglePackagesInfoModal
    }
  }
})
</script>

<style scoped>
@layer components {
  .card {
    @apply w-72;
    min-width: max-content;
  }
  
  .quote-title {
    @apply text-sm text-blue-400;
  }

  .quote-title :deep(strong) {
    color: inherit;
  }
  
  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .progress-box {
    width: 60px;
    height: 60px;
  }
  
  .progress-box > svg {
    widows: 60px;
    height: 60px;
  }
  
  .progress-icon {
  @apply absolute;
  @apply z-10;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply rounded-full;
  color: #068CC4;
  background-color: rgba(6, 140, 196, 0.2);
  width: 35px;
  height: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
}
</style>
