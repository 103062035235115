
import { usePost } from '@/api/publications/usePosts'
import { defineComponent, toRefs } from 'vue'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    postId: {
      type: String,
      default: null
    },
  },

  setup (props) {
    const { postId } = toRefs(props)

    const { t } = useI18n()

    const {
      isPostLoaded,
      fetchPost,
      deletePost,
    } = usePost()
    
    const askDelete = () => {
      Swal.fire({
        title: t('state.remove_content.title'),
        text: t('state.remove_content.message'),
        showCancelButton: true,
        confirmButtonText: t('state.remove_content.action_confirm'),
        cancelButtonText: t('state.remove_content.action_cancel')
      }).then(async ({ value }) => {
        if (value) {
          if (postId.value) await fetchPost(postId.value)
          await deletePost()
        }
      })
    }

    return {
      askDelete,
      isPostLoaded
    }
  }
})
