<template>
  <div>
    <div class="card mb-4 flex items-center w-full">
      <PostBotOptionsMenu
        v-if="selectedTab === 'content'"
        class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap pr-0"
      />

      <profile-hashtags-settings
        v-if="selectedTab === 'content'"
        class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
        :blockUpdate="lockAccess"
      />

      <post-copy-button
        v-if="selectedTab === 'content'"
        class="hidden lg:block border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
      />

      <post-image-editor-pixelixe
        v-if="selectedTab === 'media' && useImageEditor === 'pixelixe'"
        :block-update="lockAccess"
      />

      <post-image-editor-crello
        v-if="selectedTab === 'media' && useImageEditor === 'crello'"
        :block-update="lockAccess"
      />

      <post-download-image-button
        v-if="selectedTab === 'media'"
        class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
      />

      <post-restore-default-image
        v-if="selectedTab === 'media' && !lockAccess"
        class="hidden lg:block  border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
        data-status="activated"
      />

      <PostPreview
        class="hidden lg:block border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
        :post="post"
        :isLoadingCreative="isLoadingCreative"
      />

      <post-delete-button
        class="btn text-red-500 hidden lg:block lg:ml-auto border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
      />

      <PostOptionsMenu :blockUpdate="lockAccess" class="lg:hidden ml-auto">
        <template v-slot:options>
          <post-copy-button
            v-if="selectedTab === 'content'"
            class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
          />

          <post-restore-default-image
            v-if="selectedTab === 'media' && !lockAccess"
            class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
            data-status="activated"
          />

          <PostPreview
            class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
            :post="post"
            :isLoadingCreative="isLoadingCreative"
          />

          <post-delete-button
            class="btn text-red-500 border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
          />
        </template>
      </PostOptionsMenu>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { usePost } from '@/api/publications/usePosts'
import PostCopyButton from './PostCopyButton.vue'
import ProfileHashtagsSettings from '@/components/profile/ProfileHashtagsSettingsInContent.vue'
import PostBotOptionsMenu from './PostBotOptionsMenu.vue'
import PostImageEditorPixelixe from './PostImageEditorPixelixe.vue'
import PostImageEditorCrello from './PostImageEditorCrello.vue'
import PostDownloadImageButton from './PostDownloadImageButton.vue'
import PostRestoreDefaultImage from './PostRestoreDefaultImage.vue'
import PostDeleteButton from './PostDeleteButton.vue'
import PostPreview from '@/components/posts/post/PostPreview.vue'
import PostOptionsMenu from './PostOptionsMenu.vue'

export default defineComponent({
  components: {
    PostCopyButton,
    ProfileHashtagsSettings,
    PostBotOptionsMenu,
    PostImageEditorPixelixe,
    PostImageEditorCrello,
    PostDownloadImageButton,
    PostRestoreDefaultImage,
    PostDeleteButton,
    PostPreview,
    PostOptionsMenu
},

  props: {
    selectedTab: {
      type: String,
      default: 'content'
    },
  },
  setup () {
    const {
      lockAccess
    } = useProfile()

    const {
      post,
      isLoadingCreative
    } = usePost()

    const useImageEditor = computed(() => {
      return 'creative' in unref(post) || !unref(post).thumbnail ?
        'crello' :
        'pixelixe'
    })

    return {
      lockAccess,
      useImageEditor,
      post,
      isLoadingCreative
    }
  }
})
</script>
