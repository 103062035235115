<template>
  <div class="card" id="container-post-editor">
    <div class="card-header items-center">
      <input
        class="text-2xl font-semibold w-full border-none shadow-none p-2 focus:bg-gray-50"
        type="text"
        v-model="title"
        :placeholder="$t('page.content.form.title.placeholder')"
      />
    </div>
    <div class="card-body">
      <div class="mb-4 lg:hidden">
        <post-tags :tags="tags" show-add-button />
      </div>
      <div class="flex flex-col space-y-4">
        <div
          class="w-full p-2 whitespace-pre-wrap"
          v-if="!isEditing"
          @click="toggle"
        >
          <span v-if="content || hashtags">
            {{ content }} {{ hashtags }}
          </span>
          <span v-else class="text-gray-400">
            {{ $t('page.content.form.content.placeholder') }}
          </span>
        </div>
        <textarea
          class="w-full bg-gray-50 border-none shadow-none p-2"
          v-if="isEditing"
          v-model="content"
          :placeholder="$t('page.content.form.content.placeholder')"
          :rows="20"
          ref="textareaElement"
        >
        </textarea>
      </div>
      <hr class="my-2">
      <div class="flex justify-center space-x-4 overflow-auto">
        <span class="text-sm overflow-ellipsis overflow-hidden">
          {{ hashtagsCount }} {{ $t('page.content.sentence.hashtags') }}
        </span>
        <span class="text-sm overflow-ellipsis overflow-hidden">
          {{ wordsCount }} {{ $t('page.content.sentence.words') }}
        </span>
        <span class="text-sm overflow-ellipsis overflow-hidden">
          {{ caractersCount }} {{ $t('page.content.sentence.characters') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref } from 'vue'
import { usePost } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'
import { onClickOutside } from '@vueuse/core'
import { extractHashtags } from '@/composables/utils/ExtractHashtags'
import PostTags from './PostTags.vue'

export default defineComponent({
  components: {
    PostTags,
  },

  setup () {
    const textareaElement = ref<HTMLElement|null>(null)

    onClickOutside(textareaElement, () => {
      toggle()
    })

    const {
      isPostLoaded,
      post,
      updatePost
    } = usePost()

    const {
      isActive: isEditing,
      toggle
    } = useToggle(false)

    const title = computed({
      get: () => unref(isPostLoaded) ? unref(post).title : '',
      set: (value) => updatePost({ title: value })
    })

    const content = computed({
      get: () => {
        return unref(isPostLoaded) ? unref(post).content : ''
      },
      set: (value) => updatePost({ content: value })
    })

    const tags = computed(() => {
      const tags = unref(isPostLoaded) ? unref(post).tags : []
      return tags
    })

    const hashtags = computed(() => {
      return unref(isPostLoaded) ? unref(post).hashtags.join(' ') : ''
    })

    const wordsCount = computed(() => {
      return unref(isPostLoaded) ? unref(post).content.split(' ').length : 0
    })

    const caractersCount = computed(() => {
      return unref(isPostLoaded) ? unref(post).content.trim().length + unref(post).hashtags.join(' ').trim().length : 0
    })

    const hashtagsCount = computed(() => {
      const hashtags = unref(isPostLoaded) ? unref(post).hashtags.length : 0
      return extractHashtags(unref(content)).length + hashtags
    })

    return {
      isPostLoaded,
      isEditing,
      title,
      content,
      tags,
      hashtags,
      hashtagsCount,
      wordsCount,
      caractersCount,
      toggle,
      textareaElement
    }
  }
})
</script>
