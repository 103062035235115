<template>
  <div @click="openModal" class="cursor-pointer" v-if="!hideButton">
    <slot name="button">
      <button
        class="bg-gray-500 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
      >
        {{ label }}
      </button>
    </slot>
  </div>

  <teleport to="body">
    <transition name="fade">
      <div v-if="isOpen" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            @click.prevent="cancelModal"
            class="fixed inset-0"
            aria-hidden="true"
          >
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div
            class="card inline-block align-bottom overflow-hidden text-left transform transition-all"
            :class="sizeClass"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <slot/>

            <div
              v-if="!hideFooter"
              class="card-footer sm:flex sm:flex-row-reverse space-y-3 sm:space-y-0 sm:space-x-reverse sm:space-x-3"
            >
              <button
                :class="buttonClass"
                @click="confirmModal"
                type="button"
                class="btn w-full sm:w-auto sm:text-sm"
              >
                {{ $t('action.save') }}
              </button>
              <button
                @click="cancelModal"
                type="button"
                class="btn w-full sm:w-auto sm:text-sm"
              >
                {{ $t('action.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'default'
    },
    label: {
      type: String
    },
    open: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'close',
    'cancel',
    'confirm',
    'update:open'
  ],
  setup (props, { emit }) {
    const {
      isActive: isOpen,
      setActive: openModal,
      setInactive,
    } = useToggle(props.open)

    watch(computed(() => props.open), () => {
      isOpen.value = props.open
    })

    watch(isOpen, (status) => {
      emit('update:open', status)
    })

    const buttonClass = computed(() => {
      const options = {
        danger: ['bg-red-600', 'hover:bg-red-700', 'focus:ring-red-500'],
        success: ['bg-green-600', 'hover:bg-green-700', 'focus:ring-green-500'],
      }

      const type = props.type as keyof typeof options

      const {
        [type]: classes = ['btn btn-primary']
      } = options

      return classes
    })

    const sizeClass = computed(() => {
      const options = {
        small: ['sm:my-8', 'sm:align-middle', 'sm:max-w-lg', 'sm:w-full'],
        medium: ['sm:my-8', 'sm:align-middle', 'sm:max-w-xl', 'lg:max-w-4xl', 'sm:w-full'],
        larger: ['modal-larger'],
        full: ['m-0 rounded-none']
      }

      const size = props.size as keyof typeof options

      const {
        [size]: classes = ['btn btn-primary']
      } = options

      return classes
    })

    const closeModal = () => {
      emit('close')

      setInactive()
    }

    const cancelModal = () => {
      if (props.closeOnBackdrop) {
        emit('cancel')
        closeModal()
      }
    }

    const confirmModal = () => {
      emit('confirm')

      closeModal()
    }

    return {
      isOpen,
      openModal,
      buttonClass,
      sizeClass,
      confirmModal,
      cancelModal,
    }
  }
})
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0
  }

  .modal-larger {
    height: calc(100vh - 4rem);
    width: calc(100vw - 8rem);
    vertical-align: middle;
  }

  @media (max-width: 768px){
    .modal-larger {
      height: calc(100vh - 2rem);
      width: calc(100vw - 2rem);
    } 
  }
</style>