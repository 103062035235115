
import { computed, defineComponent, onBeforeMount, toRefs, unref } from 'vue'
import { useStore } from 'vuex'
import DashboardBreadcrumb from './DashboardBreadcrumb.vue'

export default defineComponent({
  components: { DashboardBreadcrumb },

  props: {
    title: {
      type: String,
      default: ''
    },
    breadcrumb: {
      type: Boolean,
      default: true
    },
    fullWrapper: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { title, fullWrapper } = toRefs(props)

    const store = useStore()
    
    const showTitle = computed(() => {
      return title && !!title.value.length
    })

    const contentFullWrapper = computed(() => store.state.dashboard.contentFullWrapper)

    onBeforeMount(() => {
      store.commit('dashboard/setIsFullWrapper', unref(fullWrapper))
    })

    return {
      showTitle,
      contentFullWrapper
    }
  }
})
