<template>
  <div class="card card-hover flex h-full">
    <div class="pt-4 pl-4 w-20 flex-none relative">
      <img :src="icon" alt="" srcset="" />
      <div
        class="absolute right-0 top-14 text-xl"
        v-if="isPremium"
      >
        <i class="fas fa-crown" data-v-5427fca9=""></i>
      </div>
    </div>
    <div class="flex flex-col items-start justify-center">
      <div class="card-header flex justify-between items-start w-full">
        <div class="card-title">
          {{ title }}
        </div>
        <div
          class="badge capitalize text-xs text-white bg-blue-500 rounded-full py-1"
          v-if="isNew"
        >
          {{ $t('sentence.new') }}
        </div>
      </div>
      <div class="card-body text-left">
        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isPremium: {
      type: Boolean,
      default: false
    }
  }
})
</script>
