
import { computed, defineComponent, onMounted, ref, toRefs, unref, watch, watchEffect } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { usePost } from '@/api/publications/usePosts'
import TagItem from './TagItem.vue'
import { useToggle } from '@/composables/useToggle'
import { useLabels } from '@/composables/user/useLabels'

interface ILabel {
  id: string;
  text: string;
  color: string;
  isUsed?: boolean;
}

export default defineComponent({
  components: {
    BaseModal,
    TagItem,
  },

  props: {
    postId: {
      type: String,
      default: null
    },
  },

  setup (props) {
    const { postId } = toRefs(props)
    
    const labels = ref<ILabel[]>([])
    
    const { getTags, saveTag } = useLabels()

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const { 
      isActive: isEditable,
      setActive: setEditable
    } = useToggle(false)

    const {
      isPostLoaded,
      post,
      hasUnsavedData,
      fetchPost,
      updatePost,
      savePost,
      clearStore
    } = usePost()

    const hasLabels = computed(() => labels.value.length > 0)

    const toggleLabel = (id: string) => {
      const tagIndex = unref(labels).findIndex((tag: ILabel) => tag.id === id)
      labels.value[tagIndex].isUsed = !labels.value[tagIndex].isUsed

      const tags = unref(labels)
        .filter((tag: ILabel) => tag.isUsed)
        .map((tag: ILabel) => ({
          color: tag.color,
          id: tag.id,
          text: tag.text
        }))

      updatePost({ tags })
    }

    const toggleColor = ({ id, color }: { id: string; color: string }) => {
      const tagIndex = unref(labels).findIndex((tag: ILabel) => tag.id === id)
      if (tagIndex >= 0) {
        labels.value[tagIndex].color = color
        saveTag(labels.value[tagIndex])
      }
    }

    const toggleText = ({ id, text }: { id: string; text: string }) => {
      const tagIndex = unref(labels).findIndex((tag: ILabel) => tag.id === id)
      if (tagIndex >= 0) {
        labels.value[tagIndex].text = text
        saveTag(labels.value[tagIndex])
      }
    }

    onMounted( async () => {
      const userTags = await getTags()
      labels.value = userTags
        .map((tag: ILabel) => ({
          ...tag,
          isUsed: false
        }))
    })

    const postTags = computed(() => {
      if (unref(isPostLoaded) === false) {
        return []
      }
      const tags = unref(post).tags

      if (tags === undefined) {
        return []
      }

      return tags.map((tag: any) => {
        if (typeof tag === 'string') return tag
        return tag.id
      })
    })

    watchEffect (() => {
      if (isPostLoaded.value) {
        labels.value = labels.value.map(label => {
          return {
            ...label,
            isUsed: unref(postTags).includes(label.id),
          }
        })
        setEditable()
      }
    })

    watch(open, (newValue, oldValue) => {
      if (newValue && !oldValue) {
        onOpenModal()
      }
      if (!newValue && oldValue) {
        onCloseModal()
      }
    })

    const onOpenModal = async () => {
      if (!unref(postId)) return

      await fetchPost(unref(postId))
    }

    const onCloseModal = async () => {
      if (!unref(postId)) return

      if (hasUnsavedData.value) {
        await savePost()
      }
      clearStore()
    }

    return {
      hasLabels,
      labels,
      toggleLabel,
      toggleColor,
      toggleText,
      isEditable,
      open,
      close
    }
  }
})
