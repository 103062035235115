<template>
  <div class="w-full flex justify-between">
    <div>
      <i
        class="h-5 w-5 rounded-full text-white fab text-sm inline-flex justify-center items-center mr-2"
        :class="socialIcons[schedule.customData.socialNetwork].class"
        :style="socialIcons[schedule.customData.socialNetwork].style"
      />
      {{ format(schedule.dates, 'hh:mm') }}
    </div>
    <div class="text-right">
      <schedule-status
        :isPublished="schedule.customData.isPublished"
        :scheduledTo="schedule.customData.scheduledTo"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { defineComponent } from 'vue'
import ScheduleStatus from '../schedule/ScheduleStatus.vue'

export default defineComponent({
  components: { ScheduleStatus },
  props: {
    schedule: {
      type: Object,
      default: () => ({})
    },
  },

  setup () {
    const { format } = useDateFns()

    const {
      socialIcons
    } = useSocialAccounts()

    return {
      format,
      socialIcons
    }
  }
})
</script>
