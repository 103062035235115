
import { defineComponent, ref , computed, unref, toRaw, watchEffect, watch} from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useProfileService } from '@/services/firestore/profile'
import BaseModal from '../base/BaseModal.vue'
import ProfileSettingsCard from './ProfileSettingsCard.vue'
import HashtagsGroup from './HashtagsGroup.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import { usePost } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'
import { useDebounceFn } from '@vueuse/core'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

type group = {
  default: boolean, 
  hashtags: Array<String>,
  name: string
}

export default defineComponent({
  props:{
    blockUpdate: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseModal,
    ProfileSettingsCard,
    HashtagsGroup,
    BaseTextarea,
    
  },
  emits: [
    'hashtags'
  ],
  setup (props ,{emit}) {
    const {
      isActive: isModalActive,
      setInactive: closeModal
    } = useToggle(false)

    const {
      isPostLoaded,
      post,
      updatePost, 
    } = usePost()

    const {
      t
    } = useI18n()

    const { profile } = useProfile()

    const { updateProfile } = useProfileService()

    const {
      hashtags = [],
    } = unref(profile)

    const form = ref({
       hashtags
    })

    const postHashtags = ref('')

    const formBuffer = ref(JSON.parse(JSON.stringify(form.value)))

    const updateTitle = ({id , value}: {id: number, value: string}) => {
      form.value.hashtags[id].name = value
    }

    const updateHashtags = ({id , hashtags}: {id: number, hashtags: string[]}) => {
      form.value.hashtags[id].hashtags = hashtags
    }

    const defaultGroups = computed(() => {
      const filterDefaultGroup = form.value.hashtags.filter((group: group) => group.default == true);
      const hashtagsGroupArray: Array<string> = [];
      filterDefaultGroup.forEach(function(group: any){
        hashtagsGroupArray.push(group.hashtags)
      })
      let joiningHashtags: string[] = [];
      Array.from(hashtagsGroupArray).forEach(element => {
        joiningHashtags = joiningHashtags.concat(Array.from(element))
      });
      return joiningHashtags
    })

    const makeFirstAsDefault = () => {
      const defaultGroup = form.value.hashtags.find((hashtagsGroup: { default: boolean }) => hashtagsGroup.default === true)
      if (!defaultGroup) {
        form.value.hashtags[0].default = true
      }
    }

    const deleteGroup = (index: number) => {
      if (form.value.hashtags.length > 1) {
        Swal.fire({
          title: t('state.remove_hashtag_group.title'),
          text: t('state.remove_hashtag_group.message'),
          showCancelButton: true,
          confirmButtonText: t('state.remove_hashtag_group.action_confirm'),
          cancelButtonText: t('state.remove_hashtag_group.action_cancel')
        }).then(async ({ value }) => {
          if (value) {
            form.value.hashtags.splice(index, 1)
            makeFirstAsDefault()
          }
        })
      } else {
        Swal.fire({
          confirmButtonText: t('state.remove_unique_hashtag_group.action_confirm'),
          text: t('state.remove_unique_hashtag_group.message'),
        })
      }
    }

    const useHashtags = (hashtags: string[]) => {
      const current = postHashtags.value.split(' ')
      postHashtags.value = Array.from(new Set([...current, ...hashtags]))
        .slice(0,30)
        .join(' ')
    }

    const updateDefault = (id: number) => {
      form.value.hashtags.forEach((hashtagsGroup: { default: boolean }, index: number) => {
        hashtagsGroup.default = index === id
      })
    }

    const newGroup = () => {
      const group = { name: t('page.settings.hashtags.newGroupName'), hashtags: [], default: false }
      form.value.hashtags.push(group)
    }

    const initPostHashtags = () => {
      postHashtags.value=post.value.hashtags.join(' ')
    }

    watchEffect(() => {
      if (isPostLoaded.value) {
        initPostHashtags()
      }
    })

    watchEffect(() => {
      if (!form.value.hashtags.length) {
        newGroup()
      }
    })

    const onEditPostHashtags = useDebounceFn(() => {
      const splitArray = postHashtags.value
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((hashtag: string) => {
          return `#${hashtag.replaceAll('#', '')}`
        })
      
      postHashtags.value = Array
        .from([...new Set(splitArray)])
        .slice(0,30)
        .join(' ')
    },500)

    const saveForm = async () => {
      await updateProfile(toRaw(form.value))

      formBuffer.value = JSON.parse(JSON.stringify(form.value))

      if (isPostLoaded.value) {
        emit('hashtags', postHashtags.value)

        const validHashtags = postHashtags.value
          .trim()
          .split(' ')
          .filter((hashtag) => hashtag.length > 1)

        updatePost({ hashtags: validHashtags})
      }

      closeModal()
    }

    const resetForm = () => {
      form.value = JSON.parse(JSON.stringify(formBuffer.value))
      if (isPostLoaded.value) {
        initPostHashtags()
      }
      closeModal()
    }

    watch(isModalActive, (newValue) => {
      if (newValue === false) {
        resetForm()
      }
    })

    return {
      isPostLoaded,
      saveForm,
      form,
      updateTitle,
      updateHashtags,
      updateDefault,
      newGroup,
      defaultGroups,
      postHashtags,
      hashtags,
      onEditPostHashtags,
      deleteGroup,
      useHashtags,
      isModalActive,
      closeModal
    }
  }
})
