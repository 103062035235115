
import { computed, defineComponent, unref } from 'vue'
import PostTags from './PostTags.vue'
import { usePost } from '@/api/publications/usePosts'
import PostSchedules from './PostSchedules.vue'

export default defineComponent({
  components: {
    PostTags,
    PostSchedules
},

  setup () {
    const {
      isPostLoaded,
      post
    } = usePost()
    
    const tags = computed(() => {
      const tags = unref(isPostLoaded) ? unref(post).tags : []
      return tags
    })

    const postId = computed(() => {
      return unref(post).uid
    })

    return {
      tags,
      postId
    }
  }
})
