import { computed, toRaw, watchEffect } from 'vue'
import { useStore } from 'vuex'

export const currentUser = () => {
  const store = useStore()

  const user = computed(() => {
    return toRaw(store.getters['user/user'])
  })

  watchEffect(() => {
    if (!Object.keys(user.value).length) {
      store.dispatch('user/fetch')
    }
  })

  const updateContactInfo = async ({name, phone}: {name?: string, phone?: string}) => {
    const payload = {
      billing: {
        customer: {
          ...(name && {name}),
          ...(phone && {phone})
        }
      },
      ...(name && {name})
    }

    store.dispatch('user/update', payload)
  }

  return {
    user,
    updateContactInfo
  }
}