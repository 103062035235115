
import { useApprove, useReject } from '@/api/publications/usePosts'
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useRouter } from 'vue-router'
import PostTags from './post/PostTags.vue'
import RejectModal from './RejectModal.vue'
import PostPreview from '@/components/posts/post/PostPreview.vue'
import UserTagsModal from '../tags/UserTagsModal.vue'
import PostDeleteButton from '@/components/posts/post/PostDeleteButton.vue'
import { noCache } from '@/composables/utils/noCache'

export default defineComponent({
  components: { RejectModal, PostTags, PostPreview, UserTagsModal, PostDeleteButton },
  props: {
    post: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    rejectable: {
      type: Boolean,
      default: false
    },
    approvable: {
      type: Boolean,
      default: true
    },
    lock: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const router = useRouter()

    const { post } = toRefs(props)

    const thumbnail = computed(() => {
      let output = null
      const { creative, thumbnail, thumbnails } = unref(post)

      if (creative?.thumbnail) {
        output = creative.thumbnail
      } else if (thumbnails && thumbnails['small']) {
        output = thumbnails['small']
      } else if (thumbnail) {
        output = thumbnail
      }
      return output ? noCache(output) : null
    })

    const alternativeThumbnail = computed(() => {
      return require("@/assets/img/no-image.png")
    })

    const hasThumbnail = computed(() => unref(thumbnail) !== null)

    const postId = unref(post).uid

    const useVistaCreate = 'creative' in unref(post) || 'vistaCreateTemplate' in unref(post)

    const {
      isApproved,
      isLoading: approving,
      approve
    } = useApprove(postId, useVistaCreate)

    const {
      isRejected,
      isLoading: rejecting,
      reject
    } = useReject(postId)

    const tags = computed(() => {
      return unref(post).tags || []
    })

    const hashtags = computed(() => {
      let result = unref(post).hashtags && unref(post).hashtags.length
        ? unref(post).hashtags.join(' ')
        : ''

      if (unref(post).emphasis) {
        unref(post).emphasis.map((emphasi: string) => {
          result = result.replace(emphasi, `<span class="bg-teal-200 font-semibold px-1">${emphasi}</span>`)
        })
      }

      return result
    })

    const content = computed(() => {
      let result = unref(post).content
        ? unref(post).content.trim()
        : ''

      if (unref(post).emphasis) {
        unref(post).emphasis.map((emphasi: string) => {
          result = result.replace(emphasi, `<span class="bg-teal-200 font-semibold px-1">${emphasi}</span>`)
        })
      }

      return result
    })

    const edit = () => {
      router.push({ name: 'edit-post', params: { id: postId }})
    }

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    const showOptionsMenu = computed(() => {
      return props.editable
    })

    return {
      approve,
      reject,
      edit,
      addDefaultSrc,
      showOptionsMenu,
      hasThumbnail,
      isApproved,
      isRejected,
      approving,
      rejecting,
      thumbnail,
      alternativeThumbnail,
      tags,
      hashtags,
      content
    }
  }
})
