
// eslint-disable-next-line no-unused-vars
import { defineComponent, PropType } from 'vue'
// @ts-ignore
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

export default defineComponent({
  components: {
    VuePlyr,
  },

  props: {
    src: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String as PropType<string | null>,
      default: null,
      required: false
    },
  },

  setup () {
    const handleEvent = (log: any) => {
      console.log('Basic player event', log)
    }
    
    return {
      handleEvent
    }
  }
})
