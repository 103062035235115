
import { computed, defineComponent, ref, unref } from 'vue'
import { usePost } from '@/api/publications/usePosts'
import { slugfy } from '@/composables/utils/Slugfy'

export default defineComponent({
  emits: [
    'downloaded'
  ],
  setup (props, { emit }) {

    const isWorking = ref(false)
    
    const {
      isPostLoaded,
      hasDesign,
      design,
      post
    } = usePost()

    const fileName = computed(() => {
      return unref(isPostLoaded) && unref(post).title ? slugfy(unref(post).title) : 'manycontent_publication'
    })

    const handleDownload = () => {
      const data = unref(design.value)
      if (data) {
        isWorking.value = true
        var xhr = new XMLHttpRequest()
        xhr.open('GET', data[0], true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL
          var imageUrl = urlCreator.createObjectURL(this.response)
          var tag = document.createElement('a')
          tag.href = imageUrl
          tag.download = unref(fileName)
          document.body.appendChild(tag)
          tag.click()
          document.body.removeChild(tag)
          isWorking.value = false
          emit('downloaded')
        }
        xhr.send()
      }
    }

    return {
      handleDownload,
      hasDesign,
      isWorking
    }
  }
})
