import { computed, onBeforeMount, toRaw, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useProfile } from '../profile/useProfile'
import { useToast } from '../useToast'

export const useIdeas = () => {
  const store = useStore()
  const { t } = useI18n()

  const {
    profile
  } = useProfile()

  const isLoaded = computed(() => {
    return toRaw(store.state.ideas.isLoaded)
  })

  onBeforeMount(() => {
    if (!isLoaded.value) {
      fetchIdeas()
    }
  })

  const isGenerating = computed(() => {
    return toRaw(store.state.ideas.isGenerating)
  })
  
  const ideas = computed(() => {
    return toRaw(store.getters['ideas/available'])
  })

  const newIdeas = computed(() => {
    return toRaw(store.getters['ideas/newIdeas'])
  })

  const fetchIdeas = () => {
    if (unref(profile) && unref(profile).id) {
      store.dispatch('ideas/fetch', unref(profile).id)
    }  
  }  

  const removeIdea = async (id: number) => {
    await store.dispatch('ideas/delete', id)
    useToast.fire({
      icon: 'error',
      title: t('state.remove_content.success_title'),
      text: t('state.remove_content.success_message')
    })
  }

  const approve = async (id: number) => {
    await store.dispatch('ideas/approve', id)
    useToast.fire({
      icon: 'success',
      title: t('state.save_content.title'),
      text: t('state.save_content.message')
    })
  }

  const makeUsed = async (id: number) => {
    await store.dispatch('ideas/makeUsed', id)
  }

  const requestIdeas = async (keywords: string[]) => {
    return store.dispatch('ideas/generate', {
      keywords,
      profileId: unref(profile).id
    })
  }

  return {
    ideas,
    newIdeas,
    isGenerating,
    isLoaded,
    fetchIdeas,
    removeIdea,
    makeUsed,
    approve,
    requestIdeas
  }
}
