
import { computed, defineComponent, unref } from 'vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  props: { 
    'modelValue': String, 
  },

  emits: [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const source = 'twitter'
    const {
      isLoaded,
      socialIcons,
      availables
    } = useSocialAccounts()

    const {
      supportedDestinations
    } = usePost()

    const value = computed({ 
      get: () => props.modelValue, 
      set: (value) => emit('update:modelValue', value) 
    })

    const socialNetwork = computed(() => {
      return source in unref(availables) && unref(availables)[source] ?
      {
        source,
        ...unref(availables)[source]
      } :
      null
    })

    const isSupportedDestinations = computed(() => {
      return supportedDestinations.value[source] || false
    })

    const isEnabled = computed(() => {
      return isSupportedDestinations.value
    })

    return {
      value,
      socialNetwork,
      isLoaded,
      socialIcons,
      isEnabled,
      isSupportedDestinations
    }
  }
})
