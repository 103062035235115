<template>
  <button
    class="btn btn-primary"
    id="button-save-post"
    @click="handleSave"
  >
    <template v-if="isWorking">
      <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.wait') }}
    </template>
    <template v-else>
      <i class="fas fa-check-circle"></i> {{ $t('action.save') }}
    </template>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  setup () {
    
    const {
      savePost,
      isWorking
    } = usePost()

    const handleSave = async () => {
      await savePost()
    }

    return {
      handleSave,
      isWorking
    }
  }
})
</script>
