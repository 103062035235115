<template>
  <div
    class="flex flex-row flex-wrap items-center w-full gap-2"
    :class="showAddButton ? 'justify-start' : 'justify-end'"
  >
    <div
      v-for="tag in tags"
      :key="tag.id"
      class="rounded w-10 min-w-max h-6 py-1 px-2 flex justify-center items-center text-white text-xs font-semibold"
      :class="`tag-${tag.color}`"
    >
      {{ tag.text }}
    </div>
    <div v-if="showAddButton"
    class="rounded w-6 min-w-max h-6 py-1 px-2 flex justify-center items-center bg-gray-500 text-xs font-semibold"
    >
      <user-tags-modal
        class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
        data-status="activated"
      >
        <i class="fas fa-plus text-white"></i> 
      </user-tags-modal>  
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { defineComponent, PropType } from 'vue'
import UserTagsModal from '@/components/tags/UserTagsModal.vue'

interface Tag {
  id: string,
  color: string,
  text: string
}

type Tags = Tag[]

export default defineComponent({
  components: {
    UserTagsModal,
  },

  props: {
    tags: {
      type: Array as PropType<Tags>,
      default: () => ([])
    },
    showAddButton: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    return {}
  }
})
</script>
