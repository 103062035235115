
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    permissions: {
      type: Array,
      required: true
    },
  },
  emits: [
    'connected',
  ],
  setup (props, { emit }) {

    const login = () => {
      /*global FB*/
      FB.login(
        () => {
          emit('connected')
        },
        {
          scope: props.permissions.join(),
          auth_type: 'rerequest'
        }
      )
    }

    return {
      login
    }
  }
})
