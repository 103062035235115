
import { usePost } from '@/api/publications/usePosts'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import { useToast } from '@/composables/useToast'
import EventBus from '@/plugins/EventBus'
import { useClipboard } from '@vueuse/core'
import { defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    idea: {
      type: Object,
      default: () => ({})
    },
    canApprove: {
      type: Boolean,
      default: false
    },
    canCopy: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canUse: {
      type: Boolean,
      default: false
    },
    canCreatePost: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { idea } = toRefs(props)
    
    const { t } = useI18n()

    const router = useRouter()

    const {
      isPostLoaded,
      post,
      updatePost,
      createPost,
      savePost
    } = usePost()

    const {
      removeIdea,
      approve,
      makeUsed,
    } = useIdeas()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const handleCopy = () => {
      const sentence = unref(idea).content.replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const handleCreatePost = async () => {
      createPost({
        title: idea.value.content
      })
      await savePost()
      await makeUsed(idea.value.id)
      router.push({ name: 'edit-post', params: { id: post.value.uid }})
    }

    const handleUseIdea = async () => {
      await makeUsed(idea.value.id)
      updatePost({title: idea.value.content})
      EventBus.emit('idea.used')
    }

    return {
      isCopySupported,
      isPostLoaded,
      handleCopy,
      removeIdea,
      approve,
      handleCreatePost,
      handleUseIdea
    }
  }
})
