
import { defineComponent, onMounted } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'
import CreateContentForm from '@/components/manyBot/CreateContentForm.vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import EventBus from '@/plugins/EventBus'

export default defineComponent({
  components: { BaseModal, CreateContentForm, QuotaUsage },

  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    onMounted(() => {
      EventBus.on('content.used', () => { close() })
    })

    const {
      currentHeadlineContentPack
    } = useContentPack()

    return {
      open,
      close,
      currentHeadlineContentPack
    }
  }
})
