
import { computed, defineComponent } from 'vue'
import { usePost } from '@/api/publications/usePosts'
import BaseVideoPlayer from '@/components/base/BaseVideoPlayer.vue'
// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    BaseVideoPlayer,
    Swiper,
    SwiperSlide
  },

  setup() {
    const { isLoadingCreative, design, thumbnail, mediaType } = usePost()
    const alternativeThumbnail = computed(() => {
      return mediaType.value === "video" ? require("@/assets/img/no-image.png") : require("@/assets/img/no-image.png")
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require("@/assets/img/no-image.png")
      }
    }

    const swiperSettings = {
      loop: true,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
    }

    return {
      design,
      thumbnail,
      alternativeThumbnail,
      isLoadingCreative,
      mediaType,
      swiperSettings,
      addDefaultSrc
    };
  }
})
