import { computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useUser } from '../user/useUser'

export const useProfile = () => {
  const store = useStore()

  const clearAnalytics = () => {
    store.commit('analytics/clearStore')
  }

  const {
    isFreeTrialActive: isUserFreetrialActive
  } = useUser()

  const isLoaded = computed(() => store.state.profiles.isLoaded)

  const profile = computed(() => store.getters['profiles/current'])

  const permissions = computed(() => {
    const output = {
      readCourses: profile.value ? profile.value.plan?.partner !== 'ralf' : true
    }

    return output
  })

  const setCurrent = (profileId: string) => {
    store.commit('profiles/setCurrent', profileId)
    store.dispatch('socialAccounts/fetch', profileId)
    store.dispatch('notifications/fetch', profileId)
  }

  const partner = computed(() => profile.value?.plan?.partner || null)

  const isFreeTrial = computed(() => {
    return profile.value && profile.value.plan && profile.value.plan.identifier === 'free' 
  })

  const isFreeTrialActive = computed(() => {
    return isFreeTrial.value && isUserFreetrialActive.value
  })

  const hasActiveSubscription = computed(() => {
    return profile.value
    && profile.value.plan !== undefined
    && profile.value.plan.active === true
    && profile.value.plan.identifier !== 'free'
  })
  
  const isActive = computed(() => {
    return hasActiveSubscription.value
      || (isFreeTrial.value && isFreeTrialActive.value)
  })

  const isWizardCompleted = computed(() => {
    return profile.value
      && profile.value.step !== undefined
      && profile.value.step === -1
  })

  const lockAccess = computed(() => {
    if (isFreeTrial.value) {
      return !isFreeTrialActive.value
    }
    return !hasActiveSubscription.value
  })

  const userRules = computed(() => {
    const rules = {
      canReviewContent: !!(profile.value.plan?.features.paidContent > 0)
    }

    return rules
  })

  const loadScript = async (src: string, id?: string) => {
    const currentScript = document.querySelector(`script[src="${src}"]`)

    if (currentScript) {
      return Promise.resolve()
    }
    
    return new Promise((resolve, reject) => {
      const element = document.createElement('script')
      element.setAttribute('src', src)
      if (id) {
        element.setAttribute('id', id)
      }
      document.head.appendChild(element)

      element.addEventListener('error', reject)
      element.addEventListener('abort', reject)
      element.addEventListener('load', () => {
        element.setAttribute('data-loaded', 'true')
        resolve(element)
      })
    })
  }

  watchEffect(async () => {
    if (profile.value && profile.value.plan?.partner === 'ralf') {
      await loadScript(
        'https://cdn.respond.io/webchat/widget/widget.js?cId=f5df19b4a78ee356689f72b1219e0b6289e415a383231918c17efcb29b7341c1',
        'respondio__widget'
      )
    }
  })

  return {
    isLoaded,
    profile,
    permissions,
    setCurrent,
    isFreeTrial,
    isFreeTrialActive,
    hasActiveSubscription,
    isWizardCompleted,
    isActive,
    userRules,
    partner,
    lockAccess,
    clearAnalytics
  }
}
