
import { defineComponent } from 'vue'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  setup () {
    
    const {
      savePost,
      isWorking
    } = usePost()

    const handleSave = async () => {
      await savePost()
    }

    return {
      handleSave,
      isWorking
    }
  }
})
