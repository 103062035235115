<template>
  <base-modal
    v-model:open="showOfferModal"
    :hide-button="hideButton"
    hide-footer
    :close-on-backdrop="currentComponent.showCloseButton"
    size="larger"
  >
    <template v-slot:button>
      <slot name="button">
        <button
          class="btn btn-primary"
          @click.prevent="toggleOfferModal"
        >
          Mais
        </button>
      </slot>
    </template>
    <component
      :is="currentComponent.component"
      :image="currentComponent.image"
      :backgroundColor="currentComponent.backgroundColor"
      @closeOffer="toggleOfferModal"
    />
    <button
      class="btn btn-secondary absolute top-2 right-2"
      v-if="currentComponent.showCloseButton"
      @click.prevent="toggleOfferModal"
    >
      <i class="fas fa-times" style="margin: 0 !important"></i>
    </button>
  </base-modal>
</template>

<script lang="ts">
import { useToggle } from '@/composables/useToggle'
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import BaseModal from '../base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal },

  props: {
    offer: {
      type: String,
      default: 'subscriptionOffer'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    startActive: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const {
      isActive: showOfferModal,
      toggle: toggleOfferModal
    } = useToggle(props.startActive)

    const offers = [
      {
        name: 'subscriptionOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/SubscriptionOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-teal-300',
        showCloseButton: true
      },
      {
        name: 'editPostOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/EditPostOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-pink-400',
        showCloseButton: true
      },
      {
        name: 'freePostOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/FreePostOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-purple-500',
        showCloseButton: true
      },
      {
        name: 'analyticsOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/AnalyticsOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-yellow-400',
        showCloseButton: true
      },
      {
        name: 'paidContentOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/SubscriptionOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-teal-300',
        showCloseButton: true
      }
    ]

    const currentComponent = computed(() => {
      return offers.find(offer => offer.name === props.offer)
    })

    return {
      showOfferModal,
      toggleOfferModal,
      currentComponent
    }
  }
})
</script>
