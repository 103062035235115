
import { computed, defineComponent, unref } from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { usePost } from '@/api/publications/usePosts'
import PostCopyButton from './PostCopyButton.vue'
import ProfileHashtagsSettings from '@/components/profile/ProfileHashtagsSettingsInContent.vue'
import PostBotOptionsMenu from './PostBotOptionsMenu.vue'
import PostImageEditorPixelixe from './PostImageEditorPixelixe.vue'
import PostImageEditorCrello from './PostImageEditorCrello.vue'
import PostDownloadImageButton from './PostDownloadImageButton.vue'
import PostRestoreDefaultImage from './PostRestoreDefaultImage.vue'
import PostDeleteButton from './PostDeleteButton.vue'
import PostPreview from '@/components/posts/post/PostPreview.vue'
import PostOptionsMenu from './PostOptionsMenu.vue'

export default defineComponent({
  components: {
    PostCopyButton,
    ProfileHashtagsSettings,
    PostBotOptionsMenu,
    PostImageEditorPixelixe,
    PostImageEditorCrello,
    PostDownloadImageButton,
    PostRestoreDefaultImage,
    PostDeleteButton,
    PostPreview,
    PostOptionsMenu
},

  props: {
    selectedTab: {
      type: String,
      default: 'content'
    },
  },
  setup () {
    const {
      lockAccess
    } = useProfile()

    const {
      post,
      isLoadingCreative
    } = usePost()

    const useImageEditor = computed(() => {
      return 'creative' in unref(post) || !unref(post).thumbnail ?
        'crello' :
        'pixelixe'
    })

    return {
      lockAccess,
      useImageEditor,
      post,
      isLoadingCreative
    }
  }
})
