
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { defineComponent } from 'vue'
import ScheduleStatus from '../schedule/ScheduleStatus.vue'

export default defineComponent({
  components: { ScheduleStatus },
  props: {
    schedule: {
      type: Object,
      default: () => ({})
    },
  },

  setup () {
    const { format } = useDateFns()

    const {
      socialIcons
    } = useSocialAccounts()

    return {
      format,
      socialIcons
    }
  }
})
