
import { computed, defineComponent, ref, unref } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { isValid, isAfter } from 'date-fns'
import ChangeSocialAccounts from './ChangeSocialAccounts.vue'
import ChangeDate from './ChangeDate.vue';
import ScheduleList from './ScheduleList.vue'

export default defineComponent({
  components: {
    BaseModal,
    ChangeSocialAccounts,
    ChangeDate,
    ScheduleList
  },

  emits: [
    'publish',
    'schedule'
  ],

  setup (props, { emit }) {
    const hasSocialNetworks = ref<boolean>(false)
    const selectedSocialNetworks = ref<string[]>([])
    const publishOption = ref<'publish' | 'schedule'>('publish')
    const selectedTab = ref<'list' | 'schedule'>('schedule')
    const scheduledTo = ref<Date | null>(null)

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const changeSocialNetworks = (currentSelected: string[]) => {
      console.log(currentSelected)
      selectedSocialNetworks.value = currentSelected
    }

    const setHasSocialNetworks = (hasAccounts: boolean) => {
      hasSocialNetworks.value = hasAccounts
    }

    const setScheduleTo = (date: Date) => {
      scheduledTo.value = date
    }

    const modalSize = computed(() => {
      return publishOption.value === 'schedule' ? 'medium' : 'small'
    })

    const canSchedule = computed(() => {
      const dateToPublish = unref(scheduledTo)
      if (dateToPublish) {
        const isValidSheculeTime = isValid(dateToPublish) && (isAfter(dateToPublish, new Date()))
        return unref(selectedSocialNetworks).length && isValidSheculeTime
      }
      return false
    })

    const publish = () => {
      emit('publish', {
        socialNetworks: unref(selectedSocialNetworks)
      })
      selectedSocialNetworks.value = []
      close()
    }

    const schedule = () => {
      emit('schedule', {
        socialNetworks: unref(selectedSocialNetworks),
        scheduledTo: unref(scheduledTo)
      })
      selectedSocialNetworks.value = []
      scheduledTo.value = null
      close()
    }

    return {
      hasSocialNetworks,
      setHasSocialNetworks,
      changeSocialNetworks,
      selectedSocialNetworks,
      publishOption,
      open,
      close,
      publish,
      schedule,
      setScheduleTo,
      scheduledTo,
      canSchedule,
      modalSize,
      selectedTab
    }
  }
})
