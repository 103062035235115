
import MyIdeas from '@/components/posts/idea-generator/MyIdeas.vue'
import { computed, defineComponent, ref, unref } from 'vue'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: { MyIdeas, BaseInput, BaseYouNeedHelpMessage },
  setup () {
    const keywordInput = ref('')
    const {
      isGenerating,
      requestIdeas
    } = useIdeas()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const keywords = computed(() => {
      return unref(keywordInput)
        .split(',')
        .map(keyword => keyword.trim().toLowerCase())
        .filter(keyword => !!keyword)
    })

    const isValidKeywords = computed(() => {
      return unref(keywords).length === 3
    })

    const hasCredit = computed(() => {
      return unref(currentHeadlineContentPack) && unref(currentHeadlineContentPack).used < unref(currentHeadlineContentPack).limit
    })

    const handleRequestIdeas = async () => {
      if (unref(isValidKeywords)) {
        await requestIdeas(unref(keywords))
      }
    }

    return {
      hasCredit,
      isGenerating,
      keywordInput,
      isValidKeywords,
      keywords,
      currentHeadlineContentPack,
      handleRequestIdeas
    }
  }
})
