<template>
  <nav class="py-4">
    <ul class="flex border-b-2 pb-2 space-x-2">
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <button
          :class="['block px-4 py-2 rounded-md', { 'bg-blue-400 text-white': item.code === active }]"
          @click="handleClick(item.code)"
        >
          <span v-if="item.icon" class="mr-2">
            <i 
              :class="[
                item.icon,
                { 'text-white': item.code === active },
                { 'text-gray-500': item.code !== active }
              ]"
            ></i>
          </span>
          {{ $t(item.label) }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    active: String,
    items: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'update:active'
  ],

  setup (props, { emit }) {
    const handleClick = (value: string) => {
      emit('update:active', value) 
    }

    return {
      handleClick
    }
  }
})
</script>