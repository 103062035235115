
import { computed, defineComponent, ref, watch } from 'vue'
import MultiSelect from '@vueform/multiselect'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  components: {
    MultiSelect,
    BaseModal
  },
  
  props: {
    hasPosts: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      default: () => ([])
    },
    filters: {
      type: Array,
      default: () => ([])
    },
    order: {
      type: String,
      default: 'asc'
    }
  },

  emits : [
    'update:filters',
    'update:order'
  ],

  setup (props, { emit }) {
    const contentFilter = ref([])

    const orderByFilterSelected = ref(props.order)

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const orderByFilterOptions = computed(() => ([
      { code: 'asc', label: 'Mais recentes' },
      { code: 'desc', label: 'Mais antigos' }
    ]))

    watch(contentFilter, (filters) => {
      emit('update:filters', filters)
    })

    watch(orderByFilterSelected, (order) => {
      emit('update:order', order)
    })

    return {
      contentFilter,
      orderByFilterSelected,
      orderByFilterOptions,
      open,
      close
    }
  }
})
