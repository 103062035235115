import { useAxios } from "@/composables/useAxios"
import {
  FetchTalkInputInterface,
  ListTalksInputInterface,
  ListTalksOutputInterface,
  RenameTalkInputInterface,
  SendMessageInputInterface,
  SendMessageOutputInterface
} from "./ManybotChatGatewayInterface"

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms) )
}

const mock: any[] = []

for (let index = 1; index < 10; index++) {
  mock.push({
    id: index+'0',
    name: `Nome da ${index}º conversa realizada`,
    messages: [
      {
        role: 'user',
        content: `${index}º mensagem do user`
      },
      {
        role: 'assistant',
        content: `${index}º mensagem do assistente`
      }
    ]
  })
  
}

export class ManybotChatGateway {
  async sendMessage(props: SendMessageInputInterface): Promise<SendMessageOutputInterface> {
    const { httpFunctions } = await useAxios()

    const payload = {
      message: props.message
    }

    try {
      const { data: message } = await httpFunctions.post<SendMessageOutputInterface>(`/manybot-requests/${props.profileId}/talk/${props.id || ''}`, payload)
      return Promise.resolve(message)
    } catch (error) {
      return Promise.reject()
    }
  }

  async listTalks(props: ListTalksInputInterface): Promise<ListTalksOutputInterface[]> {
    const { httpFunctions } = await useAxios()

    try {
      const queryParams = props.type ? `?type=${props.type}` : ''
      const endPoint = `/manybot-requests/${props.profileId}/talk` + queryParams
      const { data: talks } = await httpFunctions.get<ListTalksOutputInterface[]>(endPoint)
      return Promise.resolve(talks)
    } catch (error) {
      return Promise.resolve([])
    }
  }

  async fetchTalk(props: FetchTalkInputInterface): Promise<ListTalksOutputInterface | undefined > {
    const { httpFunctions } = await useAxios()

    try {
      const { data: talk } = await httpFunctions.get<ListTalksOutputInterface>(`/manybot-requests/${props.profileId}/talk/${props.id}`)
      return Promise.resolve(talk)
    } catch (error) {
      return Promise.reject()
    }
  }

  async deleteTalk(talkId: string, profileId: string): Promise<void> {
    const { httpFunctions } = await useAxios()

    try {
      await httpFunctions.delete(`/manybot-requests/${profileId}/talk/${talkId}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.resolve()
    }
  }

  async renameTalk(props: RenameTalkInputInterface): Promise<void> {
    const { httpFunctions } = await useAxios()

    try {
      await httpFunctions.put(`/manybot-requests/${props.profileId}/talk/${props.id}`, {name: props.name})
      return Promise.resolve()
    } catch (error) {
      return Promise.resolve()
    }
  }

  async createContent(props: any): Promise<SendMessageOutputInterface> {
    const { httpFunctions } = await useAxios()

    const payload = {
      topic: props.topic,
      ...(props.target && {target: props.target}),
      ...(props.specialization && {specialization: props.specialization}),
      ...(props.audience && {audience: props.audience}),
      ...(props.text_size && {text_size: props.text_size}),
      ...(props.voice_tone && {voice_tone: props.voice_tone}),
      ...(props.focus && {focus: props.focus}),
      ...(props.goal && {goal: props.goal}),
      ...(props.language && {language: props.language})
    }

    try {
      const { data: message } = await httpFunctions.post<SendMessageOutputInterface>(`/manybot-requests/${props.profileId}/content/`, payload)
      return Promise.resolve(message)
    } catch (error) {
      return Promise.reject()
    }
  }

  async sendTask(props: any): Promise<SendMessageOutputInterface> {
    const { httpFunctions } = await useAxios()

    const {task, profileId, ...payload} = props

    // await sleep(5000)

    // return Promise.resolve({
    //   id: 'FAKE_ID',
    //   name: 'Nome da conversa',
    //   message: 'Mensagem retornada'
    // })

    try {
      const { data: message } = await httpFunctions.post<SendMessageOutputInterface>(`/manybot-requests/${profileId}/${task}`, payload)
      return Promise.resolve(message)
    } catch (error) {
      return Promise.reject()
    }
  }
}