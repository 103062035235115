<template>
  <router-link
    :to="{ name: 'create-post' }"
    class="btn btn-primary"
  >
    {{ $t('page.my_content.action.new_content') }}
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
