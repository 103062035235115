
import { defineComponent, watch } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal },

  emits: [
    'change'
  ],

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {
    const {
      isActive,
      toggle,
      setActive,
      setInactive
    } = useToggle(false)

    watch(() => props.isOpen, (newValue) => {
      if (newValue) {
        setActive()
      } else {
        setInactive()
      }
    })
    
    watch(() => isActive.value, (newValue) => {
      if (!newValue) {
        emit('change')
      }
    })

    const handleChange = (type: string) => {
      emit('change', type)
    }

    return {
      isActive,
      toggle,
      handleChange,
    }
  }
})
