<template>
  <div
    class="flex justify-center items-center space-x-2 w-full h-full"
    v-if="!isLoaded"
  >
    <i class="fas fa-spinner fa-spin text-white"></i>
    <div class="text-white">{{ $t('sentence.loading') }}</div>
  </div>

  <div
    v-if="isLoaded && !hasSchedules"
    class="text-sm"
  >
    {{$t('sentence.post_has_no_schedule')}}
  </div>

  <div class="relative wrap overflow-y-auto h-full max-h-64">
    <div class="border absolute border-opacity-20 border-gray-700 h-full border" style="left: 50%"></div>
    <div v-for="(schedules, index) in items" :key="index">
      <div class="mb-4 text-gray-400">
        {{ format(new Date(+index), 'MMMM / yyyy') }}
      </div>
      <div
        v-for="schedule in schedules"
        :key="schedule.key"
        class="mb-8 flex justify-between items-center w-full right-timeline"
      >
        <div class="w-5/12 text-right">
          {{ format(schedule.customData.scheduledTo, 'dd') }}
        </div>  
        <i
          class="h-6 w-6 z-10 rounded-full text-white fab text-base inline-flex justify-center items-center"
          :class="socialIcons[schedule.customData.socialNetwork].class"
          :style="socialIcons[schedule.customData.socialNetwork].style"
        />
        <div class="w-5/12">
          {{ format(schedule.customData.scheduledTo, 'HH:mm') }}
          <ScheduleStatus iconOnly
            :isPublished="schedule.customData.isPublished"
            :scheduledTo="schedule.customData.scheduledTo"
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { useSchedules } from '@/composables/schedule/useSchedules';
import { computed, defineComponent, onBeforeMount, unref } from 'vue'
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts';
import ScheduleStatus from '@/components/schedule/ScheduleStatus.vue';

export default defineComponent({
  components: { ScheduleStatus },

  props: {
    postId: {
      type: String,
      required: true
    },
  },

  setup(props) {
    const { format } = useDateFns();
    const { socialIcons } = useSocialAccounts();
    const { isLoaded, fetch, schedules } = useSchedules();
    onBeforeMount(async () => {
        await fetch();
    })

    const items = computed(() => {
      return unref(schedules)
        .filter((schedule: {
          customData: {
              publicationId: string;
          }
        }) => schedule.customData.publicationId === props.postId)
        .sort((a: {dates: string}, b: {dates: string}) => new Date(b.dates).getTime() - new Date(a.dates).getTime())
        .reduce((result: any, schedule: any) => {
          const key = new Date(new Date(schedule.dates).setDate(1)).setHours(0, 0, 0, 0);
          if (!(key in result)) {
              result[key] = [];
          }
          result[key].push(schedule);
          return result;
        }, {})
    })

    const hasSchedules = computed(() => {
      return !!Object.keys(unref(items)).length
    })

    return {
      format,
      hasSchedules,
      socialIcons,
      isLoaded,
      items
    };
  }
})
</script>
