
import { defineComponent, unref, toRefs, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  
  props: {
    name: String,
    label: String,
    help: String,
    maxWords:{
      type: Number, 
      default: 0
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    modelValue: {
      type: String,
      default: '',
    },
    rows: {
      type: String,
      default: '5',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    autocomplete: {
      required: false,
      type: String,
      default: '',
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: false,
    },
    ref: {
      required: false,
      type: String,
      default: '',
    },
    leftIcon: {
      required: false,
      type: String,
      default: '',
    },
    showCounter: {
      required: false,
      type: Boolean,
      default: false,
    },
    counterType: {
      required: false,
      type: String,
      default: 'characters',
    },
    counterMin: {
      required: false,
      type: Number,
      default: 50,
    },
  },

  emits : [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const { placeholder, leftIcon } = toRefs(props)

    const { t } = useI18n()

    const value = computed({ 
      get: () => props.modelValue, 
      set: (value) => emit('update:modelValue', value) 
    }) 

    const hasPlaceholder = computed(() => {
      return !!unref(placeholder).trim().length
    })

    const hasLeftIcon = computed(() => {
      return !!unref(leftIcon).trim().length
    })

    const counter = computed(() => {
      let result = 0
      if (value.value.trim().length) {
        if (props.counterType === 'characters') {
          result = value.value.trim().length
        } else {
          result = value.value.trim().split(' ').length
        }
      }
      return result
    })

    const countWords = computed(() => {
      if (props.counterType === 'characters') {
        return t('sentence.characters_counter-dynamic', { current: counter.value, min: props.counterMin })
      }
      return t('sentence.word_counter-dynamic', { current: counter.value, min: props.counterMin })
    })

    return {
      value,
      hasPlaceholder,
      hasLeftIcon,
      countWords
    }
  }
})
