
import { computed, defineComponent, ref, unref } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import FilterBar from '@/components/posts/idea-generator/FilterBar.vue'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import TheIdeas from '../../components/posts/idea-generator/TheIdeas.vue'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: {
    DashboardContent,
    FilterBar,
    TheIdeas,
    BaseYouNeedHelpMessage
},
  setup () {
    const filters = ref([])

    const orderByFilter = ref('asc')

    const {
      isLoaded,
      ideas
    } = useIdeas()

    const filteredIdeas = computed(() => {
      if (unref(filters).length === 0) return unref(ideas)

      const tagsToFilter = unref(filters)
        .filter((filter: string) => filter.startsWith('tag--'))
        .map((filter: string) => filter.replace('tag--', '').toLowerCase())

      const hashtagsToFilter = unref(filters)
        .filter((filter: string) => filter.startsWith('hashtag--'))
        .map((filter: string) => filter.replace('hashtag--', '').toLowerCase())
        
      const keywordsToFilter = unref(filters)
        .filter((filter: string) => filter.startsWith('keyword--'))
        .map((filter: string) => filter.replace('keyword--', '').toLowerCase())

      return unref(ideas).filter((idea: any) => {
        if (tagsToFilter.length) {
          const tags = idea.tags
            ? idea.tags
              .map((tag: any) => {
                return tag.text.toLowerCase()
              })
            : []
          
          const hasMatch = tagsToFilter.some((tag: string) => tags.includes(tag))
          if (hasMatch) return true
        }

        if (hashtagsToFilter.length) {
          const hashtags = idea.hashtags
            ? idea.hashtags
              .map((hashtag: any) => {
                return hashtag.toLowerCase()
              })
            : []
          
          const hasMatch = hashtagsToFilter.some((hashtag: string) => hashtags.includes(hashtag))
          if (hasMatch) return true
        }

        if (keywordsToFilter.length) {
          const keywords = idea.keywords
            ? idea.keywords
              .map((keyword: any) => {
                return keyword.toLowerCase()
              })
            : []
          
          const hasMatch = keywordsToFilter.some((keyword: string) => keywords.includes(keyword))
          if (hasMatch) return true
        }

        const content = idea.content || ''

        return unref(filters).some((filter: string) => {
          return content.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        })
      })
    })

    const availableIdeas = computed(() => {
      return unref(filteredIdeas)
        .map((ideas: any) => {
          const emphasis = unref(filters)
            .filter((filter: string) => !filter.startsWith('tag--'))
            .map((filter: string) => {
              return filter
                .replace('hashtag--', '')
                .toLowerCase()
            })

          return {
            ...ideas,
            emphasis
          }
        })
        .sort((a: any, b: any) => {
          if (unref(orderByFilter) === 'asc') {
            return b.createdAt - a.createdAt
          }
          return a.createdAt - b.createdAt
        })
    })

    const hasIdeas = computed(() => {
      return unref(isLoaded) && !!unref(ideas).length
    })

    const allIdeasKeywords = computed(() => {
      if (!unref(hasIdeas)) return []

      const keywords: any[] = [] 
      
      unref(ideas)
        .filter((idea: any) => idea.keywords)
        .map((idea: any) => {
          return idea.keywords.map((keyword: any) => {
            keywords.push(keyword)
          })
        })

      return keywords
        .reduce((previousHashtags, currentHashtags: string) => {
          return !previousHashtags.includes(currentHashtags) ? [ ...previousHashtags, currentHashtags] : previousHashtags
        }, [])
    })

    const allIdeasTags = computed(() => {
      if (!unref(hasIdeas)) return []

      const tags: any[] = [] 
      
      unref(ideas)
        .filter((idea: any) => idea.tags)
        .map((idea: any) => {
          return idea.tags.map((tag: any) => {
            tags.push({
              text: tag.text,
              color: tag.color
            })
          })
        })

      return tags
        .reduce((previousTags, currentTags: any) => {
          return {
            ...previousTags,
            [currentTags.text]: currentTags.color
          }
        }, {})
    })

    const allIdeasHashtags = computed(() => {
      if (!unref(hasIdeas)) return []

      const hashtags: any[] = [] 
      
      unref(ideas)
        .filter((idea: any) => idea.hashtags)
        .map((idea: any) => {
          return idea.hashtags.map((hashtag: any) => {
            hashtags.push(hashtag)
          })
        })

      return hashtags
        .reduce((previousHashtags, currentHashtags: string) => {
          return !previousHashtags.includes(currentHashtags) ? [ ...previousHashtags, currentHashtags] : previousHashtags
        }, [])
    })

    const filterOptions = computed(() => {
      const tags = Object.keys(unref(allIdeasTags)).map((tag: any) => ({
        name: tag,
        value: 'tag--'+tag,
        color: unref(allIdeasTags)[tag],
        type: 'tag'
      }))

      const hashtags = unref(allIdeasHashtags).map((hashtag: any) => ({
        name: hashtag,
        value: 'hashtag--'+hashtag,
        type: 'hashtag'
      }))

      const keywords = unref(allIdeasKeywords).map((keyword: any) => ({
        name: keyword,
        value: 'keyword--'+keyword,
        type: 'keyword'
      }))
      
      return [...keywords, ...tags, ...hashtags]
    })

    const isEmpty = computed(() => {
      return unref(isLoaded) && unref(ideas).length === 0
    })

    return {
      isEmpty,
      hasIdeas,
      filterOptions,
      filters,
      orderByFilter,
      availablePosts: availableIdeas,

      availableIdeas,
      isLoaded
    }
  }
})
