<template>
  <base-modal v-model:open="isActive" hide-footer hide-button>
    <div class="card-body py-8">
      <div class="text-center mb-8 font-semibold">
        {{ $t('sentence.what_type_of_content_to_create') }}
      </div>
      <div class="flex justify-around items-center gap-4">
        <button class="btn btn-primary outline" @click="handleChange('image')">
          <i class="far fa-image"></i>
          {{ $t('sentence.image') }}
        </button>
        <button class="btn btn-primary outline" @click="handleChange('image')">
          <i class="far fa-images"></i>
          {{ $t('sentence.carousel') }}
        </button>
        <button class="btn btn-primary outline" @click="handleChange('video')">
          <i class="fas fa-film"></i>
          {{ $t('sentence.video') }}
        </button>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal },

  emits: [
    'change'
  ],

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {
    const {
      isActive,
      toggle,
      setActive,
      setInactive
    } = useToggle(false)

    watch(() => props.isOpen, (newValue) => {
      if (newValue) {
        setActive()
      } else {
        setInactive()
      }
    })
    
    watch(() => isActive.value, (newValue) => {
      if (!newValue) {
        emit('change')
      }
    })

    const handleChange = (type: string) => {
      emit('change', type)
    }

    return {
      isActive,
      toggle,
      handleChange,
    }
  }
})
</script>

<style scoped>
@layer components {
  .btn {
    @apply flex flex-col gap-2 p-4 w-28;
  }

  .btn i {
    @apply m-0 text-3xl;
  }
}
</style>