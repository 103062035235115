
import { computed, defineComponent, watch } from 'vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'default'
    },
    label: {
      type: String
    },
    open: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'close',
    'cancel',
    'confirm',
    'update:open'
  ],
  setup (props, { emit }) {
    const {
      isActive: isOpen,
      setActive: openModal,
      setInactive,
    } = useToggle(props.open)

    watch(computed(() => props.open), () => {
      isOpen.value = props.open
    })

    watch(isOpen, (status) => {
      emit('update:open', status)
    })

    const buttonClass = computed(() => {
      const options = {
        danger: ['bg-red-600', 'hover:bg-red-700', 'focus:ring-red-500'],
        success: ['bg-green-600', 'hover:bg-green-700', 'focus:ring-green-500'],
      }

      const type = props.type as keyof typeof options

      const {
        [type]: classes = ['btn btn-primary']
      } = options

      return classes
    })

    const sizeClass = computed(() => {
      const options = {
        small: ['sm:my-8', 'sm:align-middle', 'sm:max-w-lg', 'sm:w-full'],
        medium: ['sm:my-8', 'sm:align-middle', 'sm:max-w-xl', 'lg:max-w-4xl', 'sm:w-full'],
        larger: ['modal-larger'],
        full: ['m-0 rounded-none']
      }

      const size = props.size as keyof typeof options

      const {
        [size]: classes = ['btn btn-primary']
      } = options

      return classes
    })

    const closeModal = () => {
      emit('close')

      setInactive()
    }

    const cancelModal = () => {
      if (props.closeOnBackdrop) {
        emit('cancel')
        closeModal()
      }
    }

    const confirmModal = () => {
      emit('confirm')

      closeModal()
    }

    return {
      isOpen,
      openModal,
      buttonClass,
      sizeClass,
      confirmModal,
      cancelModal,
    }
  }
})
