
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, ref, unref, watch, watchEffect } from 'vue'
import InstagramPublishSelector from './InstagramPublishSelector.vue'
import FacebookPublishSelector from './FacebookPublishSelector.vue'
import TwitterPublishSelector from './TwitterPublishSelector.vue'
import LinkedinPublishSelector from './LinkedinPublishSelector.vue'
import TiktokPublishSelector from './TiktokPublishSelector.vue'
import YoutubePublishSelector from './YoutubePublishSelector.vue'
import { currentUser } from '@/composables/user/currentUser'

export default defineComponent({
  components: {
    InstagramPublishSelector,
    FacebookPublishSelector,
    TwitterPublishSelector,
    LinkedinPublishSelector,
    TiktokPublishSelector,
    YoutubePublishSelector,
  },

  emits: [
    'changeSocialNetworks',
    'isLoadedSocialAccounts',
    'hasSocialNetworks'
  ],

  setup (props, { emit }) {
    const selectedSocialNetworks = ref<string[]>([])

    const { user } = currentUser()
    
    const {
      isLoaded,
      availables
    } = useSocialAccounts()

    const availableSocialNetworks = computed(() => {
      return ['instagram', 'facebook', 'linkedin', 'twitter', 'youtube']
        .map(source => {
          return source in unref(availables) && unref(availables)[source] ?
            source :
            null
        })
        .filter(account => account !== null)
    })

    watch(selectedSocialNetworks, () => {
      emit('changeSocialNetworks', selectedSocialNetworks.value)
    })

    watchEffect(() => {
      emit('hasSocialNetworks', availableSocialNetworks.value.length > 0)
    })

    const unlockExperimentalFeatures = computed(() => {
      const userIds = ['skHi69P8radCAUp0adgipYY6tpi1']
      return userIds.includes(unref(user).id)
    })

    return {
      isLoaded,
      selectedSocialNetworks,
      availableSocialNetworks,
      unlockExperimentalFeatures
    }
  }
})
