<template>
  <dashboard-content>
    <template v-slot:actions>
      <create-post-button/>
    </template>

    <div>
      <filter-bar
        :hasPosts="hasPosts"
        :options="filterOptions"
        v-model:filters="filters"
        v-model:order="orderByFilter"
      />

      <the-posts
        :posts="availablePosts"
        :loaded="isPostsLoaded"
        :approvable=false
        editable
      />
    </div>

    <div
      class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-3/4"
      v-if="isEmpty"
    >
      <div
        class="text-lg text-center leading-relaxed"
      >
        {{ $t('page.my_content.sentence.no_content_title') }}
        <br>
        {{ $t('page.my_content.sentence.no_content_another_options') }}

        <ul class="text-base text-left mt-8">
          <li>
            <router-link
              class="text-blue-600 hover:bg-white hover:underline"
              :to="{ name: 'review-posts' }"
            >
              <i class="fas fa-arrow-right text-xs mr-1"></i>
              {{ $t('page.my_content.sentence.no_content_option_review') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="text-blue-600 hover:bg-white hover:underline"
              :to="{ name: 'ready-to-use-posts' }"
            >
              <i class="fas fa-arrow-right text-xs mr-1"></i>
              {{ $t('page.my_content.sentence.no_content_option_templates') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="text-blue-600 hover:bg-white hover:underline"
              :to="{ name: 'create-post' }"
            >
              <i class="fas fa-arrow-right text-xs mr-1"></i>
              {{ $t('page.my_content.sentence.no_content_option_self_content') }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="text-sm text-center mt-12">
        <base-you-need-help-message />
      </div>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref } from 'vue'
import CreatePostButton from '@/components/posts/myPosts/CreatePostButton.vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import FilterBar from '@/components/posts/myPosts/FilterBar.vue'
import ThePosts from '@/components/posts/ThePosts.vue'
import { useMyPosts } from '@/api/publications/usePosts'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: {
    CreatePostButton,
    DashboardContent,
    FilterBar,
    ThePosts,
    BaseYouNeedHelpMessage
},
  setup () {
    const filters = ref([])

    const orderByFilter = ref('asc')

    const {
      isPostsLoaded,
      fetchPosts,
      posts
    } = useMyPosts()

    const filteredPosts = computed(() => {
      if (unref(filters).length === 0) return unref(posts)

      const tagsToFilter = unref(filters)
        .filter((filter: string) => filter.startsWith('tag--'))
        .map((filter: string) => filter.replace('tag--', '').toLowerCase())

      const hashtagsToFilter = unref(filters)
        .filter((filter: string) => filter.startsWith('hashtag--'))
        .map((filter: string) => filter.replace('hashtag--', '').toLowerCase())        

      return unref(posts).filter((post: any) => {
        if (tagsToFilter.length) {
          const tags = post.tags
            ? post.tags
              .map((tag: any) => {
                return tag.text.toLowerCase()
              })
            : []
          
          const hasMatch = tagsToFilter.some((tag: string) => tags.includes(tag))
          if (hasMatch) return true
        }

        if (hashtagsToFilter.length) {
          const hashtags = post.hashtags
            ? post.hashtags
              .map((hashtag: any) => {
                return hashtag.toLowerCase()
              })
            : []
          
          const hasMatch = hashtagsToFilter.some((hashtag: string) => hashtags.includes(hashtag))
          if (hasMatch) return true
        }

        const content = post.content || ''

        return unref(filters).some((filter: string) => {
          return content.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        })
      })
    })

    const availablePosts = computed(() => {
      return unref(filteredPosts)
        .map((post: any) => {
          const emphasis = unref(filters)
            .filter((filter: string) => !filter.startsWith('tag--'))
            .map((filter: string) => {
              return filter
                .replace('hashtag--', '')
                .toLowerCase()
            })

          return {
            ...post,
            emphasis
          }
        })
        .sort((a: any, b: any) => {
          if (unref(orderByFilter) === 'asc') {
            return b.createdDate.seconds - a.createdDate.seconds
          }
          return a.createdDate.seconds - b.createdDate.seconds
        })
    })

    const hasPosts = computed(() => {
      return unref(isPostsLoaded) && !!unref(posts).length
    })

    const allPostTags = computed(() => {
      if (!unref(isPostsLoaded)) return []

      const tags: any[] = [] 
      
      unref(posts)
        .filter((post: any) => post.tags)
        .map((post: any) => {
          return post.tags.map((tag: any) => {
            tags.push({
              text: tag.text,
              color: tag.color
            })
          })
        })

      return tags
        .reduce((previousTags, currentTags: any) => {
          return {
            ...previousTags,
            [currentTags.text]: currentTags.color
          }
        }, {})
    })

    const allPostHashtags = computed(() => {
      if (!unref(isPostsLoaded)) return []

      const hashtags: any[] = [] 
      
      unref(posts)
        .filter((post: any) => post.hashtags)
        .map((post: any) => {
          return post.hashtags.map((hashtag: any) => {
            hashtags.push(hashtag)
          })
        })

      return hashtags
        .reduce((previousHashtags, currentHashtags: string) => {
          return !previousHashtags.includes(currentHashtags) ? [ ...previousHashtags, currentHashtags] : previousHashtags
        }, [])
    })

    const filterOptions = computed(() => {
      const tags = Object.keys(unref(allPostTags)).map((tag: any) => ({
        name: tag,
        value: 'tag--'+tag,
        color: unref(allPostTags)[tag],
        type: 'tag'
      }))

      const hashtags = unref(allPostHashtags).map((hashtag: any) => ({
        name: hashtag,
        value: 'hashtag--'+hashtag,
        type: 'hashtag'
      }))
      
      return [...tags, ...hashtags]
    })

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    fetchPosts()

    return {
      isEmpty,
      isPostsLoaded,
      hasPosts,
      filterOptions,
      filters,
      orderByFilter,
      availablePosts,
    }
  }
})
</script>
