<template>
  <div>
    <DatePicker
      v-model="scheduledTo"
      mode="dateTime"
      is24hr
      :min-date="limitDateToSchedule"
      :minute-increment="5"
    >
      <template
        v-slot="{ inputValue, inputEvents }"
        v-if="isMobile"
      >
        <div class="flex flex-wrap justify-center items-center w-full">
          <div class="whitespace-nowrap m-3 text-white">{{ $t('page.publish.sentence.publish_in') }}</div>
          <input
            type="text"
            class="px-2 py-1"
            :value="inputValue"
            v-on="inputEvents"
          />
        </div>
      </template>
    </DatePicker>

    <div class="mt-4 h-6 text-white" v-if="!isMobile">
      <span class="font-semibold mr-3 text-white" v-if="scheduledTo">{{ $t('page.publish.sentence.publish_in') }}</span>
      <span class="text-white" v-if="scheduledTo">
        {{ scheduledTo.toLocaleString([], {dateStyle: 'short' ,timeStyle: 'short'}) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { isAfter } from 'date-fns'
import { DatePicker } from 'v-calendar';
import { useBreakpoints } from '@/composables/useBreakpoints'

export default defineComponent({
  components: {
    DatePicker
  },

  emits: [
    'setScheduleTo'
  ],

  setup (props, { emit }) {
    const scheduledTo = ref<Date | null>(null)

    const { breakpoints } = useBreakpoints()

    onMounted(() => {
      adjustScheduleDate()
    })

    const isMobile = computed(() => {
      return breakpoints.smaller('lg').value
    })

    const limitDateToSchedule = computed(() => {
      return new Date()
    })

    const getNextDate = (minutes: number) => {
      const date = new Date()
      const ms = 1000 * 60 * minutes
      return new Date(Math.ceil(date.getTime() / ms) * ms)
    }

    const adjustScheduleDate = () => {
      setInterval(() => {
        const dateToPublish = unref(scheduledTo)
        if (dateToPublish) {
          const limit = getNextDate(10)
          if (!isAfter(dateToPublish, limit)) {
            scheduledTo.value = limit
          }
        }
      }, 1000)
    }

    watch(scheduledTo, () => {
      emit('setScheduleTo', unref(scheduledTo))
    })
    
    return {
      scheduledTo,
      limitDateToSchedule,
      isMobile
    }
  }
})
</script>

<style>
.vc-time-content select {
  background-image: none !important;
}
.vc-time-picker {
  width: 100% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
}
.vc-container {
  --text-sm: 12px;
}
.vc-day {
  min-height: 28px;
}
</style>