
import { useToggle } from '@/composables/useToggle'
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import BaseModal from '../base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal },

  props: {
    offer: {
      type: String,
      default: 'subscriptionOffer'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    startActive: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const {
      isActive: showOfferModal,
      toggle: toggleOfferModal
    } = useToggle(props.startActive)

    const offers = [
      {
        name: 'subscriptionOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/SubscriptionOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-teal-300',
        showCloseButton: true
      },
      {
        name: 'editPostOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/EditPostOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-pink-400',
        showCloseButton: true
      },
      {
        name: 'freePostOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/FreePostOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-purple-500',
        showCloseButton: true
      },
      {
        name: 'analyticsOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/AnalyticsOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-yellow-400',
        showCloseButton: true
      },
      {
        name: 'paidContentOffer',
        component: defineAsyncComponent(() => import('@/components/freeTrial/SubscriptionOffer.vue')),
        image: 'offer',
        backgroundColor: 'bg-teal-300',
        showCloseButton: true
      }
    ]

    const currentComponent = computed(() => {
      return offers.find(offer => offer.name === props.offer)
    })

    return {
      showOfferModal,
      toggleOfferModal,
      currentComponent
    }
  }
})
