
import { useSchedules } from '@/composables/schedule/useSchedules';
import { computed, defineComponent, onBeforeMount, unref } from 'vue'
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts';
import ScheduleStatus from '@/components/schedule/ScheduleStatus.vue';

export default defineComponent({
  components: { ScheduleStatus },

  props: {
    postId: {
      type: String,
      required: true
    },
  },

  setup(props) {
    const { format } = useDateFns();
    const { socialIcons } = useSocialAccounts();
    const { isLoaded, fetch, schedules } = useSchedules();
    onBeforeMount(async () => {
        await fetch();
    })

    const items = computed(() => {
      return unref(schedules)
        .filter((schedule: {
          customData: {
              publicationId: string;
          }
        }) => schedule.customData.publicationId === props.postId)
        .sort((a: {dates: string}, b: {dates: string}) => new Date(b.dates).getTime() - new Date(a.dates).getTime())
        .reduce((result: any, schedule: any) => {
          const key = new Date(new Date(schedule.dates).setDate(1)).setHours(0, 0, 0, 0);
          if (!(key in result)) {
              result[key] = [];
          }
          result[key].push(schedule);
          return result;
        }, {})
    })

    const hasSchedules = computed(() => {
      return !!Object.keys(unref(items)).length
    })

    return {
      format,
      hasSchedules,
      socialIcons,
      isLoaded,
      items
    };
  }
})
