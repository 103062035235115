<template>
  <div
    v-if="newIdeas.length"
  >
    <transition-group
      class="grid grid-cols-1 gap-6"
      name="list"
      tag="div"
    >
      <idea
        v-for="(idea) in newIdeas"
        :key="idea.id"
        :idea="idea"
        :can-approve="!isPostLoaded"
        :can-copy="!isPostLoaded"
        :can-use="isPostLoaded"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import Idea from './Idea.vue'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  components: { Idea },
  setup () {
    const {
      newIdeas,
      removeIdea,
    } = useIdeas()

    const {
      isPostLoaded,
    } = usePost()

    return {
      isPostLoaded,
      newIdeas,
      removeIdea
    }
  }
})
</script>

<style scoped>
.list-move, .list-enter-active, .list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from, .list-leave-to {
  opacity: 0;
}
</style>