import { ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

export const usePreventLeave = (callback: any) => {
  const isEditing = ref(false)

  const { t } = useI18n()

  onBeforeRouteLeave(async () => {
    if (isEditing.value === true) {
      const { isConfirmed } = await Swal.fire({
        html: t('state.prevent_leave.message-html'),
        showConfirmButton: true,
        confirmButtonText: t('state.prevent_leave.action_confirm'),
        showCancelButton: true,
        cancelButtonText: t('state.prevent_leave.action_cancel')
      })

      if (isConfirmed) {
        callback()
      }
    }

    return true
  })

  return {
    isEditing,
  }
}
