const pixelixe = {
  apikey: process.env.VUE_APP_PIXELIXE_API_KEY,
  outputUrl: process.env.VUE_APP_PIXELIXE_OUTPUT_URL
}

const getUrl = () => {
  return 'https://studio.pixelixe.com/#api'
}

const getApiKey = () => {
  return pixelixe.apikey
}

const getOutputURL = () => {
  return pixelixe.outputUrl
}

export default { getUrl, getApiKey, getOutputURL }