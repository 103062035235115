import { computed, toRaw } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { addMinutes, isAfter } from 'date-fns'

export const useSchedules = () => {
  const store = useStore()

  const { id: profileId, user: userId } = store.getters['profiles/current']
  
  const isLoaded = computed(() => {
    return store.state.schedules.isLoaded
  })

  const schedules = computed(() => {
    return toRaw(store.getters['schedules/all'])
  })

  const getStatus = (isPublished: boolean, scheduledTo: Date) => {
    const limitToSchedule = addMinutes(scheduledTo, 5)
    
    if (isPublished) {
      return 'published'
    }

    if (!isPublished && isAfter(new Date(), limitToSchedule)) {
      return 'error'
    }
    
    return 'waiting'
  }

  const remove = (scheduleId: string) => {
    return store.dispatch('schedules/remove', { profileId, scheduleId })
  }

  const fetch = () => {
    return store.dispatch('schedules/fetch', { profileId, userId })
  }

  return {
    isLoaded,
    schedules,
    fetch,
    remove,
    getStatus
  }
}
