<template>
  <div
    class="flex flex-wrap justify-between"
    :class="{ 'mb-8': !contentFullWrapper }"
  >
    <div class="w-full">
      <slot name="notification"/>
    </div>
    <div class="flex flex-col justify-start mb-4" v-if="breadcrumb || showTitle">
      <dashboard-breadcrumb v-if="breadcrumb" />
      <h1 v-if="showTitle">{{ title }}</h1>
    </div>

    <div class="flex flex-grow items-center justify-end">
      <slot name="actions"/>
    </div>
  </div>
  <div
    :class="{ 'mb-16': !contentFullWrapper }"
  >
    <slot/>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, toRefs, unref } from 'vue'
import { useStore } from 'vuex'
import DashboardBreadcrumb from './DashboardBreadcrumb.vue'

export default defineComponent({
  components: { DashboardBreadcrumb },

  props: {
    title: {
      type: String,
      default: ''
    },
    breadcrumb: {
      type: Boolean,
      default: true
    },
    fullWrapper: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { title, fullWrapper } = toRefs(props)

    const store = useStore()
    
    const showTitle = computed(() => {
      return title && !!title.value.length
    })

    const contentFullWrapper = computed(() => store.state.dashboard.contentFullWrapper)

    onBeforeMount(() => {
      store.commit('dashboard/setIsFullWrapper', unref(fullWrapper))
    })

    return {
      showTitle,
      contentFullWrapper
    }
  }
})
</script>
