
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    active: String,
    items: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'update:active'
  ],

  setup (props, { emit }) {
    const handleClick = (value: string) => {
      emit('update:active', value) 
    }

    return {
      handleClick
    }
  }
})
