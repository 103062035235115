
import { usePost } from '@/api/publications/usePosts'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { defineComponent, ref, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n'
// import { useRouter } from 'vue-router'
import { useDateFns } from '@/composables/locale/useDateFns'
import EventBus from '@/plugins/EventBus'

export default defineComponent({
  props: {
    automatedContent: {
      type: Object,
      default: () => ({})
    },
    canCopy: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canCreatePost: {
      type: Boolean,
      default: false
    },
    canUse: {
      type: Boolean,
      default: false
    },
    showContentProps: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { automatedContent } = toRefs(props)
    const isCreatingPost = ref(false)
    
    const { t } = useI18n()

    // const router = useRouter()

    const {
      isPostLoaded,
      updatePost,
      createPost,
      savePost
    } = usePost()

    const {
      remove,
      makeUsed,
    } = useAutomatedContent()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const { format } = useDateFns()

    const handleCopy = () => {
      const sentence = unref(automatedContent).content.replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const handleCreatePost = async () => {
      isCreatingPost.value = true
      createPost({
        title: automatedContent.value.headline,
        content: automatedContent.value.content
      })
      await savePost()
      await makeUsed(automatedContent.value.id)
      isCreatingPost.value = false
      // router.push({ name: 'edit-post', params: { id: post.value.uid }})
    }

    const handleUsePost = async () => {
      await makeUsed(automatedContent.value.id)
      updatePost({content: automatedContent.value.content})
      EventBus.emit('content.used')
    }

    return {
      isCreatingPost,
      isCopySupported,
      isPostLoaded,
      handleCopy,
      remove,
      handleCreatePost,
      handleUsePost,
      format
    }
  }
})
