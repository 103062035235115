
import { defineComponent } from 'vue'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import Idea from './Idea.vue'

export default defineComponent({
  components: { Idea },

  props: {
    ideas: {
      type: Object,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    canApprove: {
      type: Boolean,
      default: false
    },
    canCopy: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canCreatePost: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const {
      newIdeas,
      removeIdea,
    } = useIdeas()

    return {
      newIdeas,
      removeIdea
    }
  }
})
