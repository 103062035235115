<template>
  <base-modal
    v-model:open="open"
    hide-footer
    size="larger"
  >
    <template v-slot:button>
      <button
        class="btn w-full border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
        :title="$t('page.posts.content_generator_title')"
      >
        <i class="far fa-file-alt"></i> Gerar conteúdo
      </button>
    </template>

    
    <div class="flex flex-col h-full justify-between">
      <div class="card-header">
        <div class="card-title">
          {{ $t('page.posts.content_generator_title') }}  
        </div>
        <quota-usage
          v-if="currentHeadlineContentPack"
          :current="currentHeadlineContentPack.used"
          :limit="currentHeadlineContentPack.limit"
          :title="$t('sentence.used_credit')"
        />
      </div>
  
      <div class="card-body h-full overflow-y-auto">
        <create-content-form />
      </div>
      
      <div class="card-footer flex justify-end space-x-3">
        <button class="btn" @click.prevent="close">
          {{ $t('action.cancel') }}
        </button>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'
import CreateContentForm from '@/components/manyBot/CreateContentForm.vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import EventBus from '@/plugins/EventBus'

export default defineComponent({
  components: { BaseModal, CreateContentForm, QuotaUsage },

  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    onMounted(() => {
      EventBus.on('content.used', () => { close() })
    })

    const {
      currentHeadlineContentPack
    } = useContentPack()

    return {
      open,
      close,
      currentHeadlineContentPack
    }
  }
})
</script>
