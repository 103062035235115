<template>
  <button
    class="btn border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
    :disabled="blockUpdate || !isPostLoaded"
    @click="onOpenEditor"
  >
    <i class="far fa-image"></i>
    {{ $t('page.content.action.image') }}
  </button>
  <ChangeCreativeTypeModal @change="handleChangeMediaTypeModal" :is-open="showChangeMediaTypeModal" />
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref } from 'vue'
import { useCreative, usePost } from '@/api/publications/usePosts'
import { useRouter } from 'vue-router'
import crello from '@/plugins/crello'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { currentUser } from '@/composables/user/currentUser'
import { useBreakpoints } from '@/composables/useBreakpoints'
import ChangeCreativeTypeModal from './ChangeCreativeTypeModal.vue'
import {isProduction} from '@/main'
// import { useProfile } from '@/composables/profile/useProfile'

declare global {
  interface Window {
    CrelloEditor:any
  }
}

interface PublishedDesign {
  id: string
  url: string
  format: 'jpg' | 'jpeg' | 'png' | 'gif' | 'mp4'
  extension: 'jpg' | 'jpeg' | 'png' | 'gif' | 'mp4' | 'zip'
  projectName: string
  width: number
  height: number
  measureUnits: string
}

export default defineComponent({
  components: { ChangeCreativeTypeModal },

  props: {
    blockUpdate: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const showChangeMediaTypeModal = ref(false)
    const router = useRouter()
    
    const { t, locale } = useI18n()

    const {
      isPostLoaded,
      hasUnsavedData,
      post,
      mediaType,
      savePost
    } = usePost()

    const { breakpoints } = useBreakpoints()

    const { user } = currentUser()

    const isMobile = computed(() => {
      return breakpoints.smaller('lg').value
    })

    const postId = computed(() => {
      return unref(isPostLoaded) ? unref(post).uid : null
    })

    const lang = computed(() => {
      return unref(locale).slice(0, 2).toLowerCase()
    })

    const { update: updateCreative } = useCreative(unref(postId))

    const editorParams = computed(() => {
      if (!unref(isPostLoaded)) return {}

      const designId = unref(post).creative?.id
      const customTemplateId = unref(post).vistaCreateTemplate?.id

      if (designId) {
        return { designId }
      } else if(customTemplateId) {
        // TODO: Verificar tipo de template (templateId ou customTemplateId)
        return { customTemplateId }
      }
      
      return {}
    })

    const vistaCreateUniqueId = computed(() => {
      let vistaCreateUniqueId = unref(user).id
      if ('vistaCreateUniqueId' in unref(user)) {
        vistaCreateUniqueId = unref(user).vistaCreateUniqueId
      }
      return vistaCreateUniqueId
    })

    const onOpenEditor = async () => {
      if (unref(hasUnsavedData)) {
        Swal.fire({
          html: t('state.save_before_edit_image.message-html'),
          showCancelButton: true,
          confirmButtonText: t('state.save_before_edit_image.action_confirm'),
          cancelButtonText: t('state.save_before_edit_image.action_cancel')
        }).then(async ({ value }) => {
          if (value) {
            await savePost()
            router.push({ name: 'edit-post', params: { id: unref(postId) }})
            handleVideoType()
          }
        })
      } else {
        if (unref(isMobile)) {
          Swal.fire({
            title: t('state.image_editor_resolution_error.title'),
            html: t('state.image_editor_resolution_error.message-html'),
            confirmButtonText: t('state.image_editor_resolution_error.action_confirm'),
          })
        } else {
          handleVideoType()
        }
      }
    }

    const handleVideoType = () => {
      if (!('designId' in editorParams.value) && !('customTemplateId' in editorParams.value)) {
        showChangeMediaTypeModal.value = true
      } else {
        openEditor(mediaType.value === 'video' ? 'video' : 'image')
      }
    }

    const handleChangeMediaTypeModal = (mediaType: 'image' | 'video' | undefined) => {
      showChangeMediaTypeModal.value = false
      if (mediaType) {
        openEditor(mediaType)
      }
    }

    const loadScript = async (src: string) => {
      return new Promise((resolve, reject) => {
        const element = document.createElement('script')
        element.setAttribute('src', src)
        document.head.appendChild(element)
  
        element.addEventListener('error', reject)
        element.addEventListener('abort', reject)
        element.addEventListener('load', () => {
          element.setAttribute('data-loaded', 'true')
          resolve(element)
        })
      })
    }

    const onPublishAction = async (publishedData: PublishedDesign) => {
      const { id, url, extension, format, width, height } = publishedData

      await updateCreative(id, url, extension, format, width, height)
    }

    const openEditor = async (mediaType: 'image' | 'video') => {
      let downloadFormat = 'png'

      const sidebarTabs = [
        'designs',
        'photos',
        'text',
        'objects',
        'background',
        'library',
        'brandkit'
      ]

      if (mediaType === 'video') {
        sidebarTabs.push('video')
        downloadFormat = 'mp4'
      }

      try {
        if (!('CrelloEditor' in window)) {
          await loadScript(crello.getScriptUrl())
        }

        const payload = {
          apiKey: crello.getApiKey(),
          designType: 'instagramSM',
          downloadFormat,
          ...unref(editorParams),
          user: {
            externalUserId: unref(vistaCreateUniqueId)
          },
          sidebarTabs: sidebarTabs,
          lockPages: false,
          lang: unref(lang),
          onPublishAction
        }

        if (!isProduction) {
          console.log(payload)
        }

        window.CrelloEditor
          .init(payload)
          // .then((editorApi: any) => {
          //   editorApi.addImage({ image: 'https://manycontent.com/logo.png' })
          //   if (unref(profile).logo) {
          //     editorApi.addImage({ image: unref(profile).logo })
          //   }
          // })
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: t('state.image_editor_error.title'),
          html: t('state.image_editor_error.message-html'),
          confirmButtonText: t('state.image_editor_error.action_confirm'),
        })
      }
    }

    return {
      onOpenEditor,
      isPostLoaded,
      postId,
      handleChangeMediaTypeModal,
      showChangeMediaTypeModal
    }
  }
})
</script>
