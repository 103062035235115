
import { computed, defineComponent, ref, unref } from 'vue'
import { usePost } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'
import { onClickOutside } from '@vueuse/core'
import { extractHashtags } from '@/composables/utils/ExtractHashtags'
import PostTags from './PostTags.vue'

export default defineComponent({
  components: {
    PostTags,
  },

  setup () {
    const textareaElement = ref<HTMLElement|null>(null)

    onClickOutside(textareaElement, () => {
      toggle()
    })

    const {
      isPostLoaded,
      post,
      updatePost
    } = usePost()

    const {
      isActive: isEditing,
      toggle
    } = useToggle(false)

    const title = computed({
      get: () => unref(isPostLoaded) ? unref(post).title : '',
      set: (value) => updatePost({ title: value })
    })

    const content = computed({
      get: () => {
        return unref(isPostLoaded) ? unref(post).content : ''
      },
      set: (value) => updatePost({ content: value })
    })

    const tags = computed(() => {
      const tags = unref(isPostLoaded) ? unref(post).tags : []
      return tags
    })

    const hashtags = computed(() => {
      return unref(isPostLoaded) ? unref(post).hashtags.join(' ') : ''
    })

    const wordsCount = computed(() => {
      return unref(isPostLoaded) ? unref(post).content.split(' ').length : 0
    })

    const caractersCount = computed(() => {
      return unref(isPostLoaded) ? unref(post).content.trim().length + unref(post).hashtags.join(' ').trim().length : 0
    })

    const hashtagsCount = computed(() => {
      const hashtags = unref(isPostLoaded) ? unref(post).hashtags.length : 0
      return extractHashtags(unref(content)).length + hashtags
    })

    return {
      isPostLoaded,
      isEditing,
      title,
      content,
      tags,
      hashtags,
      hashtagsCount,
      wordsCount,
      caractersCount,
      toggle,
      textareaElement
    }
  }
})
