
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import RecentAutomatedContents from '@/components/posts/content-generator/RecentAutomatedContents.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import { useProfile } from '@/composables/profile/useProfile'
import { usePost } from '@/api/publications/usePosts'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: { RecentAutomatedContents, BaseInput, BaseYouNeedHelpMessage },

  setup () {
    const headlineInput = ref('')

    const {
      isGenerating,
      requestContents
    } = useAutomatedContent()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const {
      profile
    } = useProfile()

    const {
      post,
      isPostLoaded
    } = usePost()

    watchEffect(() => {
      if (isPostLoaded.value && post.value.title) {
        headlineInput.value = post.value.title
      }
    })

    const category = computed(() => {
      const profileData = unref(profile)
      return profileData?.category ? profileData?.category.name : null
    })

    const headline = computed(() => {
      return unref(headlineInput).trim().replace('\n', '')
    })

    const isValidKeywords = computed(() => {
      return unref(headline).length >= 3
    })

    const handleRequestIdeas = async () => {
      if (unref(isValidKeywords)) {
        await requestContents(unref(headline))
      }
    }

    const hasCredit = computed(() => {
      return unref(currentHeadlineContentPack) && unref(currentHeadlineContentPack).used < unref(currentHeadlineContentPack).limit
    })

    return {
      hasCredit,
      headlineInput,
      isGenerating,
      isValidKeywords,
      category,
      handleRequestIdeas
    }
  }
})
