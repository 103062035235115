import { differenceInCalendarDays } from 'date-fns'
import { computed, toRaw } from 'vue'
import { useStore } from 'vuex'

export const useUser = () => {
  const store = useStore()

  const fetch = () => {
    return store.dispatch('user/fetch')
  }

  const user = computed(() => store.state.user.user)

  const isLoaded = computed(() => store.state.user.isLoaded)

  const isFreeTrialActive = computed(() => {
    const userData = toRaw(user.value)
    return userData.isFreeTrial && userData.createdAt
      ? differenceInCalendarDays(new Date(), userData.createdAt.toDate()) <= 7
      : false
  })

  return {
    fetch,
    user,
    isLoaded,
    isFreeTrialActive
  }
}