<template>
  <div class="p-4 flex flex-col gap-4">
    <div class="text-gray-600 text-sm font-bold flex items-center">
      <i class="fas fa-tags text-base mr-2"></i>
      {{ $t('page.tags.action.open_modal') }}
    </div>
    <div>
      <post-tags :tags="tags" show-add-button />
    </div>
    <div class="text-gray-600 text-sm font-bold flex items-center">
      <i class="far fa-calendar-alt text-base mr-2"></i>
      {{ $t('nav.sidebar.schedule') }}
    </div>
    <div>
      <PostSchedules :post-id="postId" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import PostTags from './PostTags.vue'
import { usePost } from '@/api/publications/usePosts'
import PostSchedules from './PostSchedules.vue'

export default defineComponent({
  components: {
    PostTags,
    PostSchedules
},

  setup () {
    const {
      isPostLoaded,
      post
    } = usePost()
    
    const tags = computed(() => {
      const tags = unref(isPostLoaded) ? unref(post).tags : []
      return tags
    })

    const postId = computed(() => {
      return unref(post).uid
    })

    return {
      tags,
      postId
    }
  }
})
</script>

<style scoped>

</style>