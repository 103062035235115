<template>
  <base-modal
    v-model:open="open"
    hide-footer
    size="larger"
  >
    <template v-slot:button>
      <button 
        class="btn w-full border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
        :title="$t('page.posts.idea_generator_title')">
        <i class="far fa-lightbulb"></i> Gerar Ideias
      </button>
    </template>

    
    <div class="flex flex-col h-full justify-between">
      <div class="card-header">
        <div class="card-title">
          {{ $t('page.posts.idea_generator_title') }}  
        </div>
        <quota-usage
          v-if="currentHeadlineContentPack"
          :current="currentHeadlineContentPack.used"
          :limit="currentHeadlineContentPack.limit"
          :title="$t('sentence.used_credit')"
        />
      </div>
  
      <div class="card-body h-full overflow-y-auto">
        <idea-generator-form />
      </div>
      
      <div class="card-footer flex justify-end space-x-3">
        <button class="btn" @click.prevent="close">
          {{ $t('action.cancel') }}
        </button>
      </div>
    </div>

    <!-- <button
      class="btn btn-secondary absolute top-2 right-2"
      @click.prevent="close"
    >
      <i class="fas fa-times" style="margin: 0 !important"></i>
    </button> -->

  </base-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'
import IdeaGeneratorForm from '../idea-generator/IdeaGeneratorForm.vue'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import EventBus from '@/plugins/EventBus'

export default defineComponent({
  components: { BaseModal, IdeaGeneratorForm, QuotaUsage },

  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    onMounted(() => {
      EventBus.on('idea.used', () => { close() })
    })

    const {
      currentHeadlineContentPack
    } = useContentPack()

    return {
      open,
      close,
      currentHeadlineContentPack
    }
  }
})
</script>

<style scoped>

</style>