<template>
  <button
    class="btn"
    @click="handleCopy"
    v-if="isSupported && hasContent"
  >
    <i class="fas fa-copy"></i> {{ $t('page.content.action.copy_text') }}
  </button>
</template>

<script lang="ts">
import { usePost } from '@/api/publications/usePosts'
import { useToast } from '@/composables/useToast'
import { useClipboard } from '@vueuse/core'
import { computed, defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {
    const { copy, isSupported } = useClipboard()

    const { t } = useI18n()

    const {
      isPostLoaded,
      hasContent,
      post
    } = usePost()

    const content = computed(() => {
      const result = unref(isPostLoaded) ? unref(post).content : ''
      const hashtags = unref(isPostLoaded) ? unref(post).hashtags.join(' ') : ''
      return result + ' ' + hashtags
    })

    const handleCopy = () => {
      copy(unref(content))
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    return {
      isSupported,
      hasContent,
      handleCopy
    }
  }
})
</script>
