
import { usePost } from '@/api/publications/usePosts'
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSchedules } from '@/composables/schedule/useSchedules'
import { computed, defineComponent, onBeforeMount, unref } from 'vue'
import ScheduledPostData from './ScheduledPostData.vue'

export default defineComponent({
  components: { ScheduledPostData },
  setup () {
    const {
      isLoaded,
      fetch,
      schedules
    } = useSchedules()

    const {
      isPostLoaded,
      post
    } = usePost()

    const { format } = useDateFns()

    onBeforeMount(async () => {
      await fetch()
    })

    const groupedSchedules = computed(() => {
      if (!unref(isPostLoaded)) {
        return []
      }
      return unref(schedules)
        .filter((schedule: { customData: { publicationId: string } }) => schedule.customData.publicationId === unref(post).uid)
        .reduce((result: any, schedule: any) => {
          const key = new Date(schedule.dates).setHours(0, 0, 0, 0)
          if (!(key in result)) {
            result[key] = []  
          }
          result[key].push(schedule)
          return result
        }, {})
    })

    const hasSchedules = computed(() => {
      return !!Object.keys(unref(groupedSchedules)).length
    })

    return {
      format,
      isLoaded,
      hasSchedules,
      groupedSchedules,
    }
  }
})
