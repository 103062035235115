<template>
  <div v-if="!loaded">
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-8">
      <div
        class="card animate-pulse flex flex-col relative"
        v-for="n in 12" :key="`preview-${n}`"
      >
        <div class="bg-gray-300 w-full h-64 rounded-tl-lg rounded-tr-lg"></div>
        <div class="flex-auto p-4">
          <div
            class="whitespace-normal font-flow text-3xl leading-5 text-gray-300"
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure, eum natus enim.
          </div>
        </div>
        <div class="card-footer flex items-center justify-end">
          <div class="bg-gray-300 rounded h-10 w-2/5"></div>
        </div>
      </div>
    </div>
  </div>
  <transition-group
    v-if="loaded && posts.length"
    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-8"
    name="list"
    tag="div"
  >
    <post-card
      v-for="post in posts"
      :key="post.uid"
      :post="post"
      :editable="editable"
      :rejectable="rejectable"
      :approvable="approvable && !lock"
      :lock="lock"
    />
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PostCard from '@/components/posts/PostCard.vue'

export default defineComponent({
  components: {
    PostCard
  },
  props: {
    posts: {
      type: Object,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    rejectable: {
      type: Boolean,
      default: false
    },
    approvable: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped>
  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }

  .list-leave-active {
    /* position: absolute; */
  }
</style>

