<template>
  <div class="card" >
    <div class="card-body p-4">
      <div class="border-gray-300 border-l-4 pl-3">
        <p>{{idea.content}}</p>
        <small>{{idea.keywords.join(', ')}}</small>
      </div>
      <div class="flex justify-between items-center space-x-4 mt-2">
        <div>
          <template v-if="canDelete">
            <button
              class="btn btn-danger ghost"
              @click="removeIdea(idea.id)"
              :title="$t('action.delete')"
            >
              <i class="fa fa-trash"></i>
              {{ $t('action.delete') }}
            </button>
          </template>
        </div>
        <div class="flex gap-4">
          <template v-if="canCopy">
            <button
              class="btn"
              @click="handleCopy()"
              :title="$t('action.copy')"
              v-if="isCopySupported"
            >
              <i class="fas fa-copy"></i>
              {{ $t('action.copy') }}
            </button>
          </template>
          <template v-if="canApprove">
            <button
              class="btn btn-primary"
              @click="approve(idea.id)"
              :title="$t('action.save')"
            >
              <i class="fas fa-check-circle"></i>
              {{ $t('action.save') }}
            </button>
          </template>
          <template v-if="canCreatePost">
            <button
              class="btn btn-primary"
              @click="handleCreatePost()"
              :title="$t('page.my_content.action.new_content')"
            >
              <i class="far fa-file"></i>
              {{ $t('page.my_content.action.new_content') }}
            </button>
          </template>
          <template v-if="canUse && isPostLoaded">
            <button
              class="btn btn-primary"
              @click="handleUseIdea()"
              :title="$t('action.use_idea')"
            >
              <i class="fas fa-file-import"></i>
              {{ $t('action.use_idea') }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { usePost } from '@/api/publications/usePosts'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import { useToast } from '@/composables/useToast'
import EventBus from '@/plugins/EventBus'
import { useClipboard } from '@vueuse/core'
import { defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    idea: {
      type: Object,
      default: () => ({})
    },
    canApprove: {
      type: Boolean,
      default: false
    },
    canCopy: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canUse: {
      type: Boolean,
      default: false
    },
    canCreatePost: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { idea } = toRefs(props)
    
    const { t } = useI18n()

    const router = useRouter()

    const {
      isPostLoaded,
      post,
      updatePost,
      createPost,
      savePost
    } = usePost()

    const {
      removeIdea,
      approve,
      makeUsed,
    } = useIdeas()

    const {
      copy,
      isSupported: isCopySupported
    } = useClipboard()

    const handleCopy = () => {
      const sentence = unref(idea).content.replace(/(<([^>]+)>)/gi, "")
      copy(sentence)
      useToast.fire({
        icon: 'success',
        title: t('state.copy_to_clipboard.title'),
        text: t('state.copy_to_clipboard.message')
      })
    }

    const handleCreatePost = async () => {
      createPost({
        title: idea.value.content
      })
      await savePost()
      await makeUsed(idea.value.id)
      router.push({ name: 'edit-post', params: { id: post.value.uid }})
    }

    const handleUseIdea = async () => {
      await makeUsed(idea.value.id)
      updatePost({title: idea.value.content})
      EventBus.emit('idea.used')
    }

    return {
      isCopySupported,
      isPostLoaded,
      handleCopy,
      removeIdea,
      approve,
      handleCreatePost,
      handleUseIdea
    }
  }
})
</script>
