import { computed, onBeforeMount, toRaw, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useProfile } from '../profile/useProfile'
import { useToast } from '../useToast'

export const useAutomatedContent = () => {
  const store = useStore()
  const { t } = useI18n()

  const {
    profile
  } = useProfile()

  const isLoaded = computed(() => {
    return toRaw(store.state.automatedContent.isLoaded)
  })

  onBeforeMount(() => {
    if (!isLoaded.value) {
      fetchAutomatedContents()
    }
  })

  const isGenerating = computed(() => {
    return toRaw(store.state.automatedContent.isGenerating)
  })
  
  const recent = computed(() => {
    return toRaw(store.getters['automatedContent/recent'])
  })

  const available = computed(() => {
    return toRaw(store.getters['automatedContent/available'])
  })

  const fetchAutomatedContents = () => {
    if (unref(profile) && unref(profile).id) {
      store.dispatch('automatedContent/fetch', unref(profile).id)
    }  
  }  

  const remove = async (id: number) => {
    await store.dispatch('automatedContent/delete', id)
    useToast.fire({
      icon: 'error',
      title: t('state.remove_content.success_title'),
      text: t('state.remove_content.success_message')
    })
  }

  const approve = async (id: number) => {
    await store.dispatch('automatedContent/approve', id)
    useToast.fire({
      icon: 'success',
      title: t('state.save_content.title'),
      text: t('state.save_content.message')
    })
  }

  const makeUsed = async (id: number) => {
    await store.dispatch('automatedContent/makeUsed', id)
  }

  const requestContents = async (headline: string) => {
    return store.dispatch('automatedContent/generate', {
      headline,
      profileId: unref(profile).id
    })
  }

  return {
    recent,
    available,
    isGenerating,
    isLoaded,
    fetchAutomatedContents,
    remove,
    makeUsed,
    approve,
    requestContents
  }
}
