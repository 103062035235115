<template>
  {{ $t('sentence.you_need_help') }}
  <a class="text-blue-600" :href="suportURL" target="_blank">{{ $t('action.support_team') }}</a>
</template>

<script lang="ts">
import { useProfile } from '@/composables/profile/useProfile';
import { computed, defineComponent } from 'vue'

export default defineComponent({
  setup () {

    const {partner} = useProfile()
    
    const suportURL = computed(() => {
      if (partner.value === 'ralf') {
        return 'https://bader.es/contacto'
      }
      return 'https://suporte.manycontent.com/'
    })

    return {suportURL}
  }
})
</script>
