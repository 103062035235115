
import { defineComponent } from 'vue'
import PostCard from '@/components/posts/PostCard.vue'

export default defineComponent({
  components: {
    PostCard
  },
  props: {
    posts: {
      type: Object,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    rejectable: {
      type: Boolean,
      default: false
    },
    approvable: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    }
  }
})
