<template>
  <div class="tag flex flex-col relative">
    <div class="flex items-center">
      <input
        class="pl-12 pr-10 py-4 w-full border rounded focus:bg-gray-50"
        type="text"
        placeholder="Dê um nome para essa etiqueta"
        v-model="text"
      />
      <button
        class="rounded h-10 w-10 ml-1 p-1 absolute"
        @click.prevent="setActive"
      >
        <div
          class="border border-gray-300 rounded h-full w-full flex justify-center items-center"
          :class="`tag-${tag.color}`"
        >
          <i class="btn-edit fas fa-palette text-white hidden"></i>
        </div>
      </button>
      <button
        class="btn-edit absolute p-2 right-2 transform duration-300 ease-in-out hover:scale-125"
        @click.prevent="changeTag"
        v-if="editable"
      >
        <i v-if="tag.isUsed" class="fas fa-check-circle text-green-500"></i>
        <i v-else class="fas fa-circle text-gray-300"></i>
      </button>
      <button
        class="btn btn-save-text absolute right-4 bg-white hidden"
        @click.prevent="changeText"
      >
        Salvar
      </button>
    </div>
    <transition name="fade">
      <div
        class="card shadow-none absolute border w-full px-3 py-4 flex justify-start z-10"
        v-if="isEditing"
        ref="cardRef"
      >
        <button
          class="w-6 h-6 mr-2 flex justify-center items-center"
          @click.prevent="setInactive"
        >
          <i class="fas fa-arrow-left text-gray-700"/>
        </button>
        <div class="flex justify-around w-full">
          <button
            v-for="color in colors"
            :key="color"
            class="p-2 rounded w-6 h-6 flex justify-center items-center"
            :class="`tag-${color}`"
            @click.prevent="changeColor(color)"
          >
            <i class="fas fa-check-circle text-white text-sm" v-if="color === tag.color" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { useToggle } from '@/composables/useToggle'
import { defineComponent, onMounted, readonly, ref, toRefs, unref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    tag: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:modelValue',
    'toggle-selection',
    'toggle-color',
    'toggle-text'
  ],

  setup (props, { emit }) {
    const cardRef = ref<HTMLDivElement>()

    const { tag } = toRefs(props)

    const text = ref('')

    const {
      isActive: isEditing,
      setInactive,
      setActive
    } = useToggle(false)

    const colors = readonly([
      'cyan',
      'blue',
      'green',
      'red',
      'pink',
      'purple',
      'amber',
      'deep-orange',
      'grey',
      'black'
    ])

    const changeTag = () => emit('toggle-selection', unref(tag).id)

    const changeColor = (color: string) => emit('toggle-color', {
      id: unref(tag).id,
      color
    })

    const changeText = () => {
      emit('toggle-text', {
        id: unref(tag).id,
        text: unref(text)
      })
    }

    onMounted(() => {
      text.value = unref(tag).text

      onClickOutside(cardRef, () => {
        setInactive()
      })
    })

    return {
      cardRef,
      text,
      colors,
      isEditing,
      setActive,
      setInactive,
      changeTag,
      changeColor,
      changeText
    }
  }
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0
}

.tag:hover .btn-edit {
  display: block;
}

input:focus ~ .btn-save-text,
.btn-save-text:hover {
  display: block;
}
</style>