
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { usePost } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'
import { useRouter } from 'vue-router'
import pixelixe from '@/plugins/pixelixe'
import { useAxios } from '@/composables/useAxios'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    BaseModal
  },

  props: {
    blockUpdate: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const router = useRouter()

    const { t } = useI18n()

    const iframeRef = ref<HTMLElement|null>(null)

    const isTemplateLoaded = ref(false)

    const {
      isPostLoaded,
      hasUnsavedData,
      post,
      savePost
    } = usePost()

    const {
      isActive: isOpen,
      setInactive: closeModal
    } = useToggle(false)

    const thumbnail = computed(() => {
      return unref(isPostLoaded) ? unref(post).thumbnail : []
    })

    const postId = computed(() => {
      return unref(isPostLoaded) ? unref(post).uid : null
    })

    const template = computed(() => {
      return unref(isPostLoaded) ? unref(post).template : null
    })

    const addIframe = async () => {
      try {
        let params = `?apiKey=${pixelixe.getApiKey()}`
        params += `&graphicName=${unref(postId)}`
        params += `&uid=${unref(postId)}`
        params += `&templates=true`
        params += `&outputURL=${pixelixe.getOutputURL()}/save-from-editor`
        if (unref(template)) {
          params += `&graphicUrl=${encodeURIComponent(unref(template))}`
        } else if (unref(thumbnail)) {
          const htmlFile = await getHTMLFileUrl()
          if (htmlFile) {
            params += `&graphicUrl=${htmlFile}`
          }
        } else {
          params += `&width=1080`
          params += `&height=1080`
        }

        isTemplateLoaded.value = true

        const ifrm = document.createElement('iframe')
        if (iframeRef.value !== null) {
          iframeRef.value.appendChild(ifrm)
          ifrm.setAttribute('frameborder', '0')
          ifrm.setAttribute('allowfullscreen', 'true')
          ifrm.setAttribute('src', `${pixelixe.getUrl()}${params}`)
          ifrm.setAttribute('height', '100%')
          ifrm.setAttribute('width', '100%')
        }
      } catch (error) {
        console.log(error)
        closeModal()
        Swal.fire({
          title: t('state.image_editor_error.title'),
          html: t('state.image_editor_error.message-html'),
          confirmButtonText: t('state.image_editor_error.action_confirm'),
        })
      }
    }

    const getHTMLFileUrl = async () => {
      const { httpFunctions } = await useAxios()
      const request = await httpFunctions.get(`/pixelixe/graphicUrl/${unref(postId)}`)
      if (request.status === 200 && request.data.graphicURL) {
        return request.data.graphicURL
      }
      return null
    }

    watchEffect(async () => {
      if (unref(isPostLoaded) && unref(iframeRef) && isOpen.value) {
        if (unref(hasUnsavedData)) {
          Swal.fire({
            html: t('state.save_before_edit_image.message-html'),
            showCancelButton: true,
            confirmButtonText: t('state.save_before_edit_image.action_confirm'),
            cancelButtonText: t('state.save_before_edit_image.action_cancel')
          }).then(async ({ value }) => {
            if (value) {
              await savePost()
              router.push({ name: 'edit-post', params: { id: unref(postId) }})
              await addIframe()
            } else {
              closeModal()
            }
          })
        } else {
          await addIframe()
        }
      }
    },
    {
      flush: 'post'
    })

    return {
      isTemplateLoaded,
      iframeRef,
      isOpen,
      closeModal,
    }
  }
})
