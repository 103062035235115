  <template>
  <div
    class="flex justify-center items-center space-x-2 w-full h-full"
    v-if="!isLoaded"
  >
    <i class="fas fa-spinner fa-spin text-white"></i>
    <div class="text-white">{{ $t('sentence.loading') }}</div>
  </div>

  <template v-else>
    <div
      class="lg:h-96 w-full space-y-4 overflow-y-auto"
      v-if="hasSchedules"
    >
      <div
        class="card flex flex-row items-start space-x-4 w-full p-4 hover:bg-gray-50"
        v-for="(schedules, date) in groupedSchedules"
        :key="date"
      >
        <div class="w-12 flex flex-col justify-center items-center">
          <div class="font-semibold text-xl text-indigo-800">{{ format(+date, 'dd') }}</div>
          <div class="leading-3">{{ format(+date, 'MMM') }}</div>
        </div>
        <div class="flex flex-col space-y-2 w-full">
          <scheduled-post-data
            v-for="(schedule) in schedules"
            :key="schedule.key"
            :schedule="schedule"
          />          
        </div>
      </div>
    </div>

    <div
      class="text-white text-center"
      v-else
    >
      {{$t('sentence.post_has_no_schedule')}}
    </div>

  </template>
</template>

<script lang="ts">
import { usePost } from '@/api/publications/usePosts'
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSchedules } from '@/composables/schedule/useSchedules'
import { computed, defineComponent, onBeforeMount, unref } from 'vue'
import ScheduledPostData from './ScheduledPostData.vue'

export default defineComponent({
  components: { ScheduledPostData },
  setup () {
    const {
      isLoaded,
      fetch,
      schedules
    } = useSchedules()

    const {
      isPostLoaded,
      post
    } = usePost()

    const { format } = useDateFns()

    onBeforeMount(async () => {
      await fetch()
    })

    const groupedSchedules = computed(() => {
      if (!unref(isPostLoaded)) {
        return []
      }
      return unref(schedules)
        .filter((schedule: { customData: { publicationId: string } }) => schedule.customData.publicationId === unref(post).uid)
        .reduce((result: any, schedule: any) => {
          const key = new Date(schedule.dates).setHours(0, 0, 0, 0)
          if (!(key in result)) {
            result[key] = []  
          }
          result[key].push(schedule)
          return result
        }, {})
    })

    const hasSchedules = computed(() => {
      return !!Object.keys(unref(groupedSchedules)).length
    })

    return {
      format,
      isLoaded,
      hasSchedules,
      groupedSchedules,
    }
  }
})
</script>
