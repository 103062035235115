<template>
  <base-dropdown v-model:open="isDropdownOpen">
    <button
      class="mx-2 rounded-full h-8 w-8 hover:bg-gray-200"
      id="button-post-dropdown-options"
    >
      <i class="fas fa-ellipsis-v"></i>
    </button>
    <template v-slot:options>
      <slot name="options">
        <user-tags-modal
          class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
          data-status="activated"
          @click.prevent="closeDropdown"
        />
        <post-restore-default-image
          class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
          data-status="activated"
          @click.prevent="closeDropdown"
          v-if="!blockUpdate"
        />
        <post-copy-button
          class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
          @click.prevent="closeDropdown"
        />
        <post-download-image-button
          class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
          @downloaded="closeDropdown"
        />
        <post-delete-button
          class="border-none w-full justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
          @click.prevent="closeDropdown"
        />
      </slot>
    </template>
  </base-dropdown>
</template>

<script lang="ts">
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import UserTagsModal from '@/components/tags/UserTagsModal.vue'
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import PostCopyButton from './PostCopyButton.vue'
import PostDeleteButton from './PostDeleteButton.vue'
import PostDownloadImageButton from './PostDownloadImageButton.vue'
import PostRestoreDefaultImage from './PostRestoreDefaultImage.vue'

export default defineComponent({
  components: {
    BaseDropdown,
    UserTagsModal,
    PostRestoreDefaultImage,
    PostCopyButton,
    PostDownloadImageButton,
    PostDeleteButton
  },

  props: {
    blockUpdate: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    return {
      isDropdownOpen,
      closeDropdown
    }
  }
})
</script>
