
import { defineComponent } from 'vue'
import { useAutomatedContent } from '@/composables/contentGeneration/useAutomatedContent'
import AutomatedContentItem from './AutomatedContentItem.vue'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  components: { AutomatedContentItem },
  setup () {
    const {
      recent,
      remove,
    } = useAutomatedContent()

    const {
      isPostLoaded,
    } = usePost()

    return {
      isPostLoaded,
      recent,
      remove
    }
  }
})
