<template>
  <base-dropdown v-model:open="isDropdownOpen" showArrow class="pr-4">
    <div 
      class="btn"
      v-bind="$attrs"
      :title="$t('action.use_bot')"
    >
      <i class="fas fa-robot"></i>
      {{$t('action.use_bot')}}
    </div>
    <template v-slot:options>
      <idea-generator-button
        class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
      />

      <content-generator-button v-if="!useManybot"
        class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
      />

      <create-content-button v-if="useManybot"
        class="border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100"
      />
    </template>
  </base-dropdown>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import { useToggle } from '@/composables/useToggle'
import IdeaGeneratorButton from '../idea-generator/IdeaGeneratorButton.vue'
import ContentGeneratorButton from '../content-generator/ContentGeneratorButton.vue'
import CreateContentButton from '@/components/manyBot/CreateContentButton.vue'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: {
    BaseDropdown,
    IdeaGeneratorButton,
    ContentGeneratorButton,
    CreateContentButton
  },

  props: {
    blockUpdate: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    const { profile } = useProfile()

    const useManybot = computed(() => {
      return !!profile.value.specialization?.length
    })

    return {
      isDropdownOpen,
      closeDropdown,
      useManybot
    }
  }
})
</script>

