
import { usePost } from '@/api/publications/usePosts'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import NewFacebookPermissionButton from '../social-media/NewFacebookPermissionButton.vue'

export default defineComponent({
  components: { NewFacebookPermissionButton },

  props: { 
    'modelValue': String,
    story: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const source = 'instagram'
    const isLoadedFacebookPermissions = ref<boolean>(false)
    const isLoadedInstagramBusinessVerification = ref<boolean>(false)
    const instagramBusiness = ref<boolean>(false)
    const hasInstagramPublishPermission = ref<boolean>(false)
    
    const {
      fetchFacebookPermissions,
      isInstagramBusiness,
      isLoaded,
      socialIcons,
      availables
    } = useSocialAccounts()

    const {
      supportedDestinations
    } = usePost()

    const value = computed({ 
      get: () => props.modelValue, 
      set: (value) => emit('update:modelValue', value) 
    })

    const socialNetwork = computed(() => {
      return source in unref(availables) && unref(availables)[source] ?
      {
        source,
        ...unref(availables)[source]
      } :
      null
    })

    const isInstagramValidatorsLoaded = computed(() => {
      return isLoadedFacebookPermissions.value && isLoadedInstagramBusinessVerification.value
    })

    const isEnabled = computed(() => {
      return instagramBusiness.value && hasInstagramPublishPermission.value && isSupportedDestinations.value
    })

    onMounted( async () => {
      fetchPermissions()
      checkInstagramIsBusiness()
    })

    const checkInstagramIsBusiness = async () => {
      try {
        instagramBusiness.value = await isInstagramBusiness()
      } catch (error) {
        console.log(error)
      } finally {
        isLoadedInstagramBusinessVerification.value = true
      }
    }

    const fetchPermissions = async () => {
      try {
        const permissions = await fetchFacebookPermissions()
        hasInstagramPublishPermission.value = permissions.includes('instagram_content_publish')
      } catch (error) {
        console.log(error)
      } finally {
        isLoadedFacebookPermissions.value = true
      }
    }

    const isSupportedDestinations = computed(() => {
      return supportedDestinations.value[props.story ? 'instagram-story' : source] || false
    })

    return {
      value,
      socialNetwork,
      isLoaded,
      instagramBusiness,
      hasInstagramPublishPermission,
      socialIcons,
      isEnabled,
      isInstagramValidatorsLoaded,
      isSupportedDestinations
    }
  }
})
