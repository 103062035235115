<template>
  <base-modal
    v-model:open="isModalActive"
    hide-footer
  >
    <template v-slot:button v-if="isPostLoaded">
      <button class="btn border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100" :disabled="false">
        <i class="fas fa-hashtag"></i>
        {{ $t('page.content.action.hashtags') }}
      </button>
    </template>

    <template v-slot:button v-else>
      <slot name="button">
        <profile-settings-card
          :icon="require('@/assets/img/icons/hashtags.svg')"
          :title="$t('page.settings.hashtags.title')"
          :description="$t('page.settings.hashtags.description')"
        />
      </slot>
    </template>

    <div class="card-header">
      <div>
        <div class="card-title">
          {{ isPostLoaded ? $t('page.content.modal.hashtags.title') : $t('page.settings.hashtags.title') }}
        </div>
        <small>
          {{ isPostLoaded ? $t('page.content.modal.hashtags.subtitle') : $t('page.settings.hashtags.subtitle') }}
        </small>
      </div>
    </div>

    <div class="card-body">
      <div v-if="isPostLoaded">
        <base-textarea
          name="message"          
          v-model="postHashtags"
          @input="onEditPostHashtags"
          rows="3"
          autocomplete="off"
          :autofocus="false"
          placeholder="#love #cats #animals"
          :disabled="blockUpdate"
        />
        <div class="flex justify-between">
          <span class="text-xs">{{ $t('page.settings.hashtags.max_hashtags-dynamic', { value: 30 }) }}</span>
          <span class="text-xs">{{ postHashtags.split(' ').length }} {{ $t('page.content.sentence.hashtags') }}</span>
        </div>
        <hr class="mb-4 mt-8">
      </div>
      
      <div>
        <hashtags-group 
          v-for="(item , index) in form.hashtags" 
          :key="index" 
          :titleProp="item.name" 
          :hashtagsProp="item.hashtags"
          :defaultProp="item.default"
          :id="index"
          :hashtag="item"
          :canSelectGroup="isPostLoaded && !blockUpdate"
          @update-title="updateTitle"
          @update-hashtags="updateHashtags"
          @update-default="updateDefault"
          @delete-group="deleteGroup(index)"
          @use-hashtags="useHashtags"
        />
      </div>
    </div>

    <div class="card-footer flex justify-between">
      <button
        @click="newGroup()"
        class="btn btn-secondary outline"
      >
        <i class="fas fa-plus mr-2 text-white"></i>
        {{ $t('page.settings.hashtags.new_group')}}
      </button>
      <button class="btn btn-primary" @click.prevent="saveForm">
        {{ $t('action.finish') }}
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, ref , computed, unref, toRaw, watchEffect, watch} from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useProfileService } from '@/services/firestore/profile'
import BaseModal from '../base/BaseModal.vue'
import ProfileSettingsCard from './ProfileSettingsCard.vue'
import HashtagsGroup from './HashtagsGroup.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import { usePost } from '@/api/publications/usePosts'
import { useToggle } from '@/composables/useToggle'
import { useDebounceFn } from '@vueuse/core'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

type group = {
  default: boolean, 
  hashtags: Array<String>,
  name: string
}

export default defineComponent({
  props:{
    blockUpdate: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseModal,
    ProfileSettingsCard,
    HashtagsGroup,
    BaseTextarea,
    
  },
  emits: [
    'hashtags'
  ],
  setup (props ,{emit}) {
    const {
      isActive: isModalActive,
      setInactive: closeModal
    } = useToggle(false)

    const {
      isPostLoaded,
      post,
      updatePost, 
    } = usePost()

    const {
      t
    } = useI18n()

    const { profile } = useProfile()

    const { updateProfile } = useProfileService()

    const {
      hashtags = [],
    } = unref(profile)

    const form = ref({
       hashtags
    })

    const postHashtags = ref('')

    const formBuffer = ref(JSON.parse(JSON.stringify(form.value)))

    const updateTitle = ({id , value}: {id: number, value: string}) => {
      form.value.hashtags[id].name = value
    }

    const updateHashtags = ({id , hashtags}: {id: number, hashtags: string[]}) => {
      form.value.hashtags[id].hashtags = hashtags
    }

    const defaultGroups = computed(() => {
      const filterDefaultGroup = form.value.hashtags.filter((group: group) => group.default == true);
      const hashtagsGroupArray: Array<string> = [];
      filterDefaultGroup.forEach(function(group: any){
        hashtagsGroupArray.push(group.hashtags)
      })
      let joiningHashtags: string[] = [];
      Array.from(hashtagsGroupArray).forEach(element => {
        joiningHashtags = joiningHashtags.concat(Array.from(element))
      });
      return joiningHashtags
    })

    const makeFirstAsDefault = () => {
      const defaultGroup = form.value.hashtags.find((hashtagsGroup: { default: boolean }) => hashtagsGroup.default === true)
      if (!defaultGroup) {
        form.value.hashtags[0].default = true
      }
    }

    const deleteGroup = (index: number) => {
      if (form.value.hashtags.length > 1) {
        Swal.fire({
          title: t('state.remove_hashtag_group.title'),
          text: t('state.remove_hashtag_group.message'),
          showCancelButton: true,
          confirmButtonText: t('state.remove_hashtag_group.action_confirm'),
          cancelButtonText: t('state.remove_hashtag_group.action_cancel')
        }).then(async ({ value }) => {
          if (value) {
            form.value.hashtags.splice(index, 1)
            makeFirstAsDefault()
          }
        })
      } else {
        Swal.fire({
          confirmButtonText: t('state.remove_unique_hashtag_group.action_confirm'),
          text: t('state.remove_unique_hashtag_group.message'),
        })
      }
    }

    const useHashtags = (hashtags: string[]) => {
      const current = postHashtags.value.split(' ')
      postHashtags.value = Array.from(new Set([...current, ...hashtags]))
        .slice(0,30)
        .join(' ')
    }

    const updateDefault = (id: number) => {
      form.value.hashtags.forEach((hashtagsGroup: { default: boolean }, index: number) => {
        hashtagsGroup.default = index === id
      })
    }

    const newGroup = () => {
      const group = { name: t('page.settings.hashtags.newGroupName'), hashtags: [], default: false }
      form.value.hashtags.push(group)
    }

    const initPostHashtags = () => {
      postHashtags.value=post.value.hashtags.join(' ')
    }

    watchEffect(() => {
      if (isPostLoaded.value) {
        initPostHashtags()
      }
    })

    watchEffect(() => {
      if (!form.value.hashtags.length) {
        newGroup()
      }
    })

    const onEditPostHashtags = useDebounceFn(() => {
      const splitArray = postHashtags.value
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((hashtag: string) => {
          return `#${hashtag.replaceAll('#', '')}`
        })
      
      postHashtags.value = Array
        .from([...new Set(splitArray)])
        .slice(0,30)
        .join(' ')
    },500)

    const saveForm = async () => {
      await updateProfile(toRaw(form.value))

      formBuffer.value = JSON.parse(JSON.stringify(form.value))

      if (isPostLoaded.value) {
        emit('hashtags', postHashtags.value)

        const validHashtags = postHashtags.value
          .trim()
          .split(' ')
          .filter((hashtag) => hashtag.length > 1)

        updatePost({ hashtags: validHashtags})
      }

      closeModal()
    }

    const resetForm = () => {
      form.value = JSON.parse(JSON.stringify(formBuffer.value))
      if (isPostLoaded.value) {
        initPostHashtags()
      }
      closeModal()
    }

    watch(isModalActive, (newValue) => {
      if (newValue === false) {
        resetForm()
      }
    })

    return {
      isPostLoaded,
      saveForm,
      form,
      updateTitle,
      updateHashtags,
      updateDefault,
      newGroup,
      defaultGroups,
      postHashtags,
      hashtags,
      onEditPostHashtags,
      deleteGroup,
      useHashtags,
      isModalActive,
      closeModal
    }
  }
})
</script>
