<template>
  <div class="flex flex-col justify-start my-2">
    <div class="flex content-between items-center relative w-full">
      <input
        class="text-2xl font-semibold w-full border-none shadow-none p-2 pr-8 focus:bg-gray-50"
        type="text"
        v-model="title"
        :placeholder="$t('page.settings.hashtags.form_title_placeholder')"
      />
      <i  
        class="fas fa-pencil-alt text-sm cursor-pointer mx-2 text-gray-400 absolute right-0 focus:outline-none pointer-events-none"
        tabindex="-1"
      ></i>
    </div>
    <textarea
      class="my-1"
      name="message"
      rows="3"
      autocomplete="off"
      v-model="hashtags"
      placeholder="#love #cats #animals"
    />
    <div class="flex justify-between">
      <span class="text-xs">{{ $t('page.settings.hashtags.max_hashtags-dynamic', { value: 30 }) }}</span>
      <span class="text-xs">{{ hashtagsProp.length }} {{$t('page.content.sentence.hashtags') }}</span>
    </div>
    <div class="flex justify-between items-center mt-2">
      <label class="flex items-center space-x-2">
        <input
          class="cursor-pointer"
          type="radio"
          name="setDefault"
          :value="true"
          v-model="defaults"
        />
        <span class="text-sm">
          {{ $t('page.settings.hashtags.isDefault') }}
        </span>
      </label>
      <div class="flex items-center space-x-2">
        <button
          class="btn btn-danger ghost"
          @click="deleteGroup"
        >
          {{ $t('page.settings.hashtags.delete') }}
        </button>
        <button
          v-if="canSelectGroup"
          class="btn btn-secondary"
          @click="useHashtags"
        >
          {{ $t('page.settings.hashtags.use') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'

export default defineComponent ({

  props:{
    hashtag:{
      type: Object, 
      default: () => {}
    }, 
    hashtagsProp:{
      type: Array, 
      default: () => []
    }, 
    titleProp:{
      type: String, 
      default: ''
    },
    defaultProp:{
      type: Boolean,
      required: true
    },
    canSelectGroup: {
      type: Boolean,
      required: false
    },
    id:{
      type: Number, 
      required: true
    },
  },

  emits: [
    'update-title',
    'update-default',
    'update-hashtags',
    'delete-group',
    'use-hashtags'
  ],

  setup(props, {emit}){
    const hashtagsToString = (hashtags: string[]) => {
      return hashtags
        .map((hashtag: string) => {
          return `#${hashtag.replaceAll('#', '')}`
        }) 
        .join(' ')
    }

    const deleteGroup = () => {
      emit('delete-group', props.hashtag)
    }

    const useHashtags = () => {
      emit('use-hashtags', hashtags.value.trim().split(' '))
    }

    const hashtags = computed({
      get: () => {
        return hashtagsToString(props.hashtagsProp as string[])
      },
      set: (value) => {
        hashtagsToArray(value as unknown as string)
      }
    })
    
    const defaults = computed({
      get: () => props.defaultProp,
      set: () => {
        emit('update-default', props.id)
       }
    })

    const title = computed({
      get: () => props.titleProp,
      set: (value) => {
        emit('update-title', {id: props.id, value})
      }
    })

    const hashtagsToArray = useDebounceFn((hashtags: string) => {
      const splitArray = hashtags
      .replace(/\s+/g, ' ')
      .split(' ')
      .map((hashtag: string) => {
        return `#${hashtag.replaceAll('#', '')}`
      })

      const newHashtags = Array.from([...new Set(splitArray)])
      emit('update-hashtags', {id: props.id, hashtags: newHashtags.slice(0,30)})
    }, 500)

    return {
      title,
      hashtags,
      defaults,
      deleteGroup,
      useHashtags
    }
  }
})
</script>
