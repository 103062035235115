
import { computed, defineComponent, unref } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { BaseModal },

  props: {
    limit: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 0
    },
    packages: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const {
      isActive: showPackagesInfoModal,
      toggle: togglePackagesInfoModal
    } = useToggle(false)

    const { t } = useI18n()

    const cardTitle = computed(() => {
      return props.title || t('page.review.quote.sentence.approved_content')
    })
    
    const usageText = computed(() => t('page.review.sentence.quote_current_usage_values-dynamic', { current: props.current, limit: props.limit }))

    const progress = computed(() => Math.round(props.current * 100 / props.limit))

    const packagesInfo = computed(() => {
      const currentPackIndex = props.packages.findIndex((pack: any) => pack.isCurrent)
      return {
        hasPackages: currentPackIndex >= 0,
        currentPackage: currentPackIndex + 1,
        totalPackages: props.packages.length,
        packages: props.packages.map((pack: any, index) => ({ ...pack, current: index === currentPackIndex }))
      }
    })

    const radius = 30
    const stroke = 2
    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = computed(() => unref(circumference) - unref(progress) / 100 * unref(circumference))
    const bgStrokeDashoffset = computed(() => unref(circumference) - 100 / 100 * unref(circumference))

    return {
      usageText,
      radius,
      stroke,
      circumference,
      normalizedRadius,
      strokeDashoffset,
      bgStrokeDashoffset,
      packagesInfo,
      showPackagesInfoModal,
      cardTitle,
      togglePackagesInfoModal
    }
  }
})
